/** @format */

import * as React from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// import { lines } from "../temp_data/products";
import { lines } from "../temp_data/products2_idea";

import Submenu from "./SubMenu";
import SearchBar from "./SearchBar";
import SearchSubmenu from "./SearchSubmenu";

import { Link, useLocation } from "react-router-dom";

const MenuItems = () => {
	// eslint-disable-next-line
	const [lines_filteredData, setLines_filteredData] = React.useState(lines);

	// names of lines (linije), from which submenu is opened (for example: "Grejanje")
	const [lineName, setLineName] = React.useState("");

	// names of families of products (familije) (e.g. Bakarne cevi, fiting, armatura, ...)
	const [families, setFamilies] = React.useState([]);

	// state of submenu (open or closed)
	const [submenu, setSubmenu] = React.useState(false);

	// string typed into the search textfield
	const [searchParam, setSearchParam] = React.useState("");

	// when search is set to motion, this state is set to true (to open searchSubmenu)
	const [searchSubmenu, setSearchSubmenu] = React.useState(false);

	// state of active route (for example: /prices)
	const [activeRoute, setActiveRoute] = React.useState("/");

	// set active route on page load
	const location = useLocation();
	React.useEffect(() => {
		setActiveRoute(location.pathname);
	}, [location]);

	function handleClickForPrices() {
		setActiveRoute("/prices");
	}

	const handleClick = (index, route) => {
		if (route !== "/prices") {
			setActiveRoute(route);
		}
		setLineName(lines_filteredData[index].text);
		setFamilies(lines_filteredData[index].families);
	};

	function openSubmenu() {
		setSubmenu(true);
	}

	function closeSubmenu() {
		setSubmenu(false);
		setActiveRoute(location.pathname);
	}

	function openSearchSubmenu() {
		setSearchSubmenu(true);
	}

	function closeSearchSubmenu() {
		setSearchSubmenu(false);
		setActiveRoute(location.pathname);
	}

	// ! UNICODE to ASCII Search functions
	// check all words for unicode chars
	function isUnicodeCharPresent(group) {
		// eslint-disable-next-line no-control-regex
		return /[^\u0000-\u00ff]/.test(group.name);
	}

	// convert UNICODE to ASCII following provided map
	// TODO: adjust function to work with objects
	function convertUNI2ASC(group) {
		const comparatorMap = {
			Č: "C",
			Ć: "C",
			č: "c",
			ć: "c",
			Đ: "Dj",
			đ: "dj",
			Dž: "Dz",
			Š: "S",
			š: "s",
			Ž: "Z",
			ž: "z",
		};
		const output = {};
		for (const [key, value] of Object.entries(group)) {
			if (typeof value === "string") {
				const newValue = [];
				for (const char of value) {
					comparatorMap[char] ? newValue.push(comparatorMap[char]) : newValue.push(char);
				}
				output[key] = newValue.join("");
			} else {
				output[key] = value;
			}
		}
		return output;
	}

	/**
     loop through array and check if any of the strings are UNICODE:
        isUnicodeCharPresent(group),
    if yes, then convert to ASCII:
        convertUNI2ASC(group),
    otherwise just push to new, 'clean' collection
    */
	// eslint-disable-next-line
	function checkArrayForUnicodeStrings(array) {
		const result = [];
		array.forEach((group) => {
			isUnicodeCharPresent(group) ? result.push(convertUNI2ASC(group)) : result.push(group);
		});
		return result;
	}

	// function compares cleaned array indexes from the search, and returns same ones from original array
	// eslint-disable-next-line
	function searchedResultUnicode(Array, cleanedArray, searchedResult) {
		const result = [];
		searchedResult.forEach((si) =>
			cleanedArray.forEach((ci, ind) => {
				si === ci && result.push(Array[ind]);
			}),
		);
		return result;
	}

	// new search function
	// function searchGroups(data, searchParam) {
	// 	const lines_copy = JSON.parse(JSON.stringify(data));
	// 	const searchParamASCII = convertUNI2ASC({ name: searchParam }).name.toLowerCase();

	// 	lines_copy.forEach((object) => {
	// 		object.families.forEach((family) => {
	// 			const groupsUNICODE = family.groups;
	// 			const groupsASCII = checkArrayForUnicodeStrings(groupsUNICODE);
	// 			const groupsResultASCII = groupsASCII.filter((group) => {
	// 				return group.name.toString().toLowerCase().includes(searchParamASCII);
	// 			});

	// 			// converting result back to UNICODE
	// 			const groupsResultUNICODE = searchedResultUnicode(
	// 				groupsUNICODE,
	// 				groupsASCII,
	// 				groupsResultASCII,
	// 			);

	// 			// reassigning object groups with found [result] or an empty array []
	// 			if (groupsResultUNICODE.length) {
	// 				Object.assign(family, { groups: groupsResultUNICODE });
	// 			} else if (!groupsResultUNICODE.length) {
	// 				Object.assign(family, { groups: groupsResultUNICODE });
	// 			}
	// 		});
	// 	});
	// 	const lines_filtered = [];
	// 	lines_copy.forEach((line) => {
	// 		const family = line.families.filter((family) => family.groups.length);
	// 		family.length && Object.assign(line, { families: family });
	// 		family.length && lines_filtered.push(line);
	// 	});
	// 	return lines_filtered;
	// }

	// newest search function
	function searchGroups(data, searchParam) {
		const lines_copy = JSON.parse(JSON.stringify(data));
		const isSearchParamUnicode = isUnicodeCharPresent({ name: searchParam });
		const searchParamASCII = isSearchParamUnicode
			? searchParam.toLowerCase()
			: convertUNI2ASC({ name: searchParam }).name.toLowerCase();

		lines_copy.forEach((object) => {
			object.families.forEach((family) => {
				const groupsUNICODE = family.groups;
				const groupsASCII = checkArrayForUnicodeStrings(groupsUNICODE);
				const groupsToSearch = isSearchParamUnicode ? groupsUNICODE : groupsASCII;
				const groupsResult = groupsToSearch.filter((group) => {
					return group.name.toString().toLowerCase().includes(searchParamASCII);
				});

				// converting result back to UNICODE
				const groupsResultUNICODE = isSearchParamUnicode
					? groupsResult
					: searchedResultUnicode(groupsUNICODE, groupsASCII, groupsResult);

				// reassigning object groups with found [result] or an empty array []
				if (groupsResultUNICODE.length) {
					Object.assign(family, { groups: groupsResultUNICODE });
				} else if (!groupsResultUNICODE.length) {
					Object.assign(family, { groups: groupsResultUNICODE });
				}
			});
		});
		const lines_filtered = [];
		lines_copy.forEach((line) => {
			const family = line.families.filter((family) => family.groups.length);
			family.length && Object.assign(line, { families: family });
			family.length && lines_filtered.push(line);
		});
		return lines_filtered;
	}

	// old search function
	// function searchGroups(data, searchParam) {
	// 	const lines_copy = JSON.parse(JSON.stringify(data));
	// 	lines_copy.forEach((object) => {
	// 		object.families.forEach((family) => {
	// 			const groupsUNICODE = family.groups;
	// 			const groupsASCII = checkArrayForUnicodeStrings(groupsUNICODE);
	// 			const groupsResultASCII = groupsASCII.filter((group) => {
	// 				return group.name.toString().toLowerCase().includes(searchParam);
	// 			});

	// 			// converting result back to UNICODE
	// 			const groupsResultUNICODE = searchedResultUnicode(
	// 				groupsUNICODE,
	// 				groupsASCII,
	// 				groupsResultASCII,
	// 			);

	// 			// reassigning object groups with found [result] or an empty array []
	// 			if (groupsResultUNICODE.length) {
	// 				Object.assign(family, { groups: groupsResultUNICODE });
	// 			} else if (!groupsResultUNICODE.length) {
	// 				Object.assign(family, { groups: groupsResultUNICODE });
	// 			}
	// 		});
	// 	});
	// 	const lines_filtered = [];
	// 	lines_copy.forEach((line) => {
	// 		const family = line.families.filter((family) => family.groups.length);
	// 		family.length && Object.assign(line, { families: family });
	// 		family.length && lines_filtered.push(line);
	// 	});
	// 	return lines_filtered;
	// }

	function debounce(func, timeout = 250) {
		let timer;
		return (...args) => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		};
	}
	const setSearchParamDebounced = debounce((param) => setSearchParam(param));
	// const setSearchParamDebounced = (param) => setSearchParam(param)

	React.useEffect(() => {
		// ! open searchSubmenu
		// eslint-disable-next-line
		searchParam && openSearchSubmenu();
		// eslint-disable-next-line
		if (searchParam || searchParam === "") setLines_filteredData(searchGroups(lines, searchParam));

		// eslint-disable-next-line
	}, [searchParam]);

	// TODO: react router implemented with the help of this tutorial: https://www.youtube.com/watch?v=Ul3y1LXxzdU&ab_channel=WebDevSimplified

	return (
		<>
			<Submenu
				submenu={submenu}
				closeSubmenu={closeSubmenu}
				lineName={lineName}
				families={families}
			/>
			<SearchBar
				className="ch-searchbar"
				searchParam={setSearchParamDebounced}
			/>
			<SearchSubmenu
				searchSubmenu={searchSubmenu}
				closeSearchSubmenu={closeSearchSubmenu}
				lines_filteredData={lines_filteredData}
			/>
			<div className="ch-menu-splitter">
				PRODAJNI PROGRAM
				<hr />
			</div>
			<List className="ch-list">
				{lines_filteredData.map((line, index) => (
					<React.Fragment key={index}>
						<ListItem
							disablePadding
							className="ch-menu-item"
						>
							<ListItemButton
								onClick={() => {
									handleClick(index);
									openSubmenu();
								}}
							>
								<span
									className="material-icons"
									style={{ width: 40 }}
								>
									{line.icon}
								</span>
								<ListItemText primary={line.text} />
								<ListItemIcon sx={{ color: "#D2D5D1", minWidth: 40 }}>
									<NavigateNextIcon
										// sx required to position the icon correctly (a bit to the right)
										sx={{
											position: "relative",
											left: "25px",
										}}
									/>
								</ListItemIcon>
							</ListItemButton>
						</ListItem>
					</React.Fragment>
				))}
			</List>
			<div className="ch-menu-splitter">
				PORUDŽBINE I FINANSIJE
				<hr />
			</div>
			<List className="ch-list">
				{[
					{
						route: "",
						text: "Pregled porudžbina",
						icon: "shopping_basket",
					},
					{
						route: "invoices",
						text: "Pregled faktura",
						icon: "point_of_sale",
					},
					{
						route: "finances",
						text: "Finansijsko stanje",
						icon: "account_balance_wallet",
					},
				].map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						className={`ch-menu-item ${
							activeRoute !== "/prices" && activeRoute === `/${item.route}`
								? "selected-router-link"
								: ""
						}`}
					>
						<Link
							className="router-link"
							to={`/${item.route}`}
							onClick={() => handleClick(index, `/${item.route}`)}
						>
							<ListItemButton>
								<span
									className="material-icons"
									style={{ width: 40 }}
								>
									{item.icon}
								</span>
								<ListItemText primary={item.text} />
							</ListItemButton>
						</Link>
					</ListItem>
				))}
			</List>
			<div className="ch-menu-splitter">
				CENOVNICI
				<hr />
			</div>
			<List className="ch-list">
				{[
					{
						text: "Pregled cenovnika",
						icon: "request_quote",
					},
				].map((item, index) => (
					<ListItem
						key={index}
						disablePadding
						className={`ch-menu-item ${activeRoute === "/prices" ? "selected-router-link" : ""}`}
					>
						<Link
							className="router-link"
							to="/prices"
							onClick={handleClickForPrices}
						>
							<ListItemButton>
								<span
									className="material-icons"
									style={{ width: 40 }}
								>
									{item.icon}
								</span>
								<ListItemText primary={item.text} />
							</ListItemButton>
						</Link>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default MenuItems;
