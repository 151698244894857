/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";

//Import Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";
import Chip from "@mui/material/Chip";
import { rows } from "../temp_data/table_data";
import { SideDetailsContext } from "../contexts/SideDetailsContext";

const formatDate = (date) => {
	const parsedDate = new Date(date);
	return parsedDate.toLocaleString("sr", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

const formatAddress = (streetName, streetNumber, city) => {
	if (streetNumber === null || streetNumber === "null") {
		return `${streetName}, ${city}`;
	}
	return `${streetName} ${streetNumber}, ${city}`;
};

const formatCurrency = (value) => {
	return value.toLocaleString("sr", {
		style: "currency",
		currency: "RSD",
		minimumFractionDigits: 2,
	});
};

const totalValue = (value) => {
	let sumIndex = 0;
	value.forEach((array) => {
		const lastIndex = array[array.length - 1];
		const lastIndexValue = parseFloat(lastIndex.replace(/[^\d,-]/g, "").replace(",", "."));
		sumIndex += lastIndexValue;
	});
	return sumIndex;
};

//simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example

const OrderTable = () => {
	const { setContextValue } = React.useContext(SideDetailsContext);
	const [clickedRowId, setClickedRowId] = React.useState(null);

	React.useEffect(() => {
		window.onload = () => {
			const horizontal_scroll = document.querySelector(".ch-table-container");
			horizontal_scroll.children[1].classList.add("scroll-handler");
		};
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorFn: (row) => formatDate(row.dateTimeStamp),
				id: "dateTimeStamp",
				header: "Datum porudžbine",
			},
			{
				accessorKey: "buyer",
				header: "Kupac",
			},
			{
				accessorFn: (row) => formatAddress(row.streetName, row.streetNumber, row.city),
				id: "address",
				header: "Adresa isporuke",
			},
			{
				accessorKey: "status",
				header: "Status",
				// enableSorting: false,
				size: 70,
				Cell: ({ cell, row }) => (
					<Chip
						label={cell.getValue()}
						color={row.original.color}
						style={{ width: "95px" }}
					/>
				),
			},
			{
				// accessorFn: (row) => formatCurrency(row.price),
				accessorFn: (row) => formatCurrency(totalValue(row.order.body)),
				id: "price",
				header: "Vrednost",
				size: 90,
			},
		],
		[],
	);

	function buyerAddress(streetName, streetNumber, city) {
		if (streetNumber === null || streetNumber === "" || streetNumber === undefined) {
			return `${streetName}, ${city}`;
		}
		return `${streetName} ${streetNumber}, ${city}`;
	}
	function zipCity(zipCode, city) {
		if (zipCode === null || zipCode === "" || zipCode === undefined) {
			return `${city}`;
		}
		return `${zipCode} ${city}`;
	}

	const handleTableRowClick = React.useCallback(
		(row) => {
			// ! NOTE: I gave my best here, but I couldn't find a way to pass the dateTimeStamp data using contextValue
			// session storage is used instead, and it works like a charm :) as always
			sessionStorage.setItem("order_id", row.original.id);
			sessionStorage.setItem("dateTimeStamp", row.original.dateTimeStamp);
			sessionStorage.setItem(
				"buyer_address",
				buyerAddress(row.original.streetName, row.original.streetNumber, row.original.city),
			);
			sessionStorage.setItem("buyer_name", row.original.buyer);
			sessionStorage.setItem(
				"street_name_number",
				`${row.original.streetName} ${row.original.streetNumber ?? ""}`,
			);
			sessionStorage.setItem("zip_city", zipCity(row.original.zipCode, row.original.city));
			sessionStorage.setItem("order", JSON.stringify(row.original.order.body));
			sessionStorage.setItem("total_value", formatCurrency(totalValue(row.original.order.body)));
			setContextValue(row.original);

			setClickedRowId(row.id);
		},
		[setContextValue],
	);

	const rowClassName = (rowId) => {
		return rowId === clickedRowId ? "clicked-row" : "";
	};

	return (
		<>
			<MaterialReactTable
				localization={MRT_Localization_SR_LATN_RS}
				columns={columns}
				data={rows}
				muiTablePaperProps={{
					//change the mui box shadow
					elevation: 0,
					//customize paper styles
					sx: {
						borderRadius: "0",
						// borderTop: '1px solid #e0e0e0',
						borderBottom: "1px solid #e0e0e0",
						cursor: "pointer",
					},
					className: "ch-table-container",
				}}
				enableColumnResizing
				columnResizeMode="onChange"
				muiTableBodyRowProps={({ row }) => ({
					onClick: () => handleTableRowClick(row), //consider implementing class animate-sideDetails here, instead in App.js
					className: rowClassName(row.id),
				})}
			/>
		</>
	);
};

export default OrderTable;
