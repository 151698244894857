/** @format */

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(buyer, amount, price, value) {
	return { buyer, amount, price, value };
}

export default function BasicTable(order) {
	// check here for refactor: https://chat.openai.com/c/6e4284b9-3497-4c43-b3cf-3b461bc74602
	const rows = [];
	order?.order?.body.forEach((order, index) => {
		rows.push(createData(order[2], order[4], order[8], order[9]));
	});

	return (
		<TableContainer
			sx={{
				backgroundColor: "transparent",
				boxShadow: "none",
				"& .MuiTableCell-head": {
					color: "#000",
					backgroundColor: "#f5f5f5",
				},
				"& .MuiTableCell-row": {
					color: "orange",
				},
			}}
			className="scroll-handler"
			component={Paper}
		>
			<Table
				stickyHeader
				aria-label="simple table"
			>
				<TableHead>
					<TableRow>
						<TableCell align="left">
							<b>Naziv</b>
						</TableCell>
						<TableCell align="right">
							<b>Količina</b>
						</TableCell>
						<TableCell align="right">
							<b>Cena</b>
						</TableCell>
						<TableCell align="right">
							<b>Vrednost</b>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow
							key={row.buyer + Math.floor(Math.random() * 100)}
							sx={{
								"&:last-child td, &:last-child th": { border: 0 },
								// '&:nth-of-type(odd)': {
								//   backgroundColor: "white",
								// },
								"&:nth-of-type(even)": {
									backgroundColor: "#fcfcfc",
								},
							}}
						>
							<TableCell
								component="th"
								scope="row"
								align="left"
							>
								{row.buyer}
							</TableCell>
							<TableCell align="right">
								{row.amount.toLocaleString("sr", { minimumFractionDigits: 2 })}
							</TableCell>
							<TableCell align="right">
								{row.price.toLocaleString("sr", { minimumFractionDigits: 2 })}
							</TableCell>
							<TableCell align="right">
								{row.value.toLocaleString("sr", { minimumFractionDigits: 2 })}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
