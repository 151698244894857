/** @format */

import * as React from "react";

// import ReactDOM from 'react-dom';

import Table from "./OrderTable";
import InvoicesTable from "./InvoicesTable";
import FinancialStateTable from "./FinancialStateTable";
import PriceList from "./PriceList";
import ProductsPage from "./ProductsPage";
import { Route, Routes } from "react-router-dom";

const MainContent = () => {
	const el1 = React.useRef(null);
	const el2 = React.useRef(null);

	const elementsOverlap = (el1, el2) => {
		const domRect1 = el1.getBoundingClientRect();
		const domRect2 = el2?.getBoundingClientRect();

		return !(
			domRect1.top > domRect2.bottom ||
			domRect1.right < domRect2.left ||
			domRect1.bottom < domRect2.top ||
			domRect1.left > domRect2.right
		);
	};
	const handleOverlap = () => {
		if (elementsOverlap(el1.current, el2.current)) {
			el1.current.style.visibility = "hidden";
		} else {
			el1.current.style.visibility = "visible";
		}
	};

	const tableResizeObserver = new ResizeObserver((entries) => {
		entries.forEach((entry) => handleOverlap());
	});

	React.useEffect(() => {
		window.addEventListener("resize", handleOverlap);

		tableResizeObserver.observe(el2.current);

		// cleanup component to prevent memory leaks
		return () => {
			window.removeEventListener("resize", handleOverlap);
		};

		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div
				className="ch-header"
				ref={el1}
			>
				<p>HEADER CONTENT</p>
			</div>
			<div
				className="ch-table-wrapper scroll-handler"
				ref={el2}
			>
				<Routes>
					<Route
						path="/"
						element={<Table />}
					/>
					<Route
						path="/invoices"
						// element={<p style={{ color: "black", textAlign: "center" }}>INVOICES TEST PAGE</p>}
						element={<InvoicesTable />}
					/>
					<Route
						path="/finances"
						// element={<p style={{ color: "black", textAlign: "center" }}>FINANCES TEST PAGE</p>}
						element={<FinancialStateTable />}
					/>
					<Route
						path="/prices"
						// element={<p style={{ color: "black", textAlign: "center" }}>PRICES TEST PAGE</p>}
						element={<PriceList />}
					/>
					<Route
						path="/products/:id"
						element={<ProductsPage />}
					/>
					<Route
						path="*"
						element={<p style={{ color: "black", textAlign: "center" }}>404 NOT FOUND</p>}
					/>
				</Routes>
			</div>
		</>
	);
};

export default MainContent;
