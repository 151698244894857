/** @format */

import React from "react";
import styled from "styled-components";
import Modal from "./PDFExcelModal";

const PriceListContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	align-items: center;
	color: black;
	overflow: hidden;
`;
const PriceListCard = styled.div`
	display: flex;
	flex-direction: column;
	flex-basis: calc(25% - 20px);
	align-items: center;
	justify-content: center;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	box-sizing: border-box;
	padding: 10px;
	margin: 10px;
	/* margin: calc(5%); */
	min-width: 150px;
	min-height: fit-content;
	/* width: -webkit-fill-available; */ /* if uncommented cards will grow in size until the they fill the screen */
	max-width: 150px;
	aspect-ratio: 1/1;
	cursor: pointer;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
	height: -webkit-fill-available;
`;

const sampleData = [
	{
		id: 1,
		name: "Company A",
		logo: "https://img.logoipsum.com/222.svg",
		priceList: {
			header: [
				"R. Br.",
				"Slika",
				"Šifra artikla",
				"Naziv",
				"J. M.",
				"VP Cena",
				"Rabat (%)",
				"Neto cena",
			],
			body: [
				[
					1,
					"slika proizvoda 1",
					123456,
					"Naziv proizvoda 1",
					"kom",
					"1.100,00",
					"25,00%",
					"1.100,00",
				],
				[
					2,
					"slika proizvoda 2",
					123456,
					"Naziv proizvoda 2",
					"kom",
					"2.100,00",
					"15,00%",
					"2.100,00",
				],
			],
			footer: "bla, bla",
		},
	},
	{
		id: 2,
		name: "Company B",
		logo: "https://img.logoipsum.com/223.svg",
		priceList: {
			header: [
				"R. Br.",
				"Slika",
				"Šifra artikla",
				"Naziv",
				"J. M.",
				"VP Cena",
				"Rabat (%)",
				"Neto cena",
			],
			body: [
				[1, "slika 4", 123456, "Naziv proizvoda 4", "kom", "3.100,00", "25,00%", "3.100,00"],
				[2, "slika 5", 123456, "Naziv proizvoda 5", "kom", "4.100,00", "15,00%", "4.100,00"],
				[3, "slika 6", 123456, "Naziv proizvoda 6", "kom", "5.100,00", "16,00%", "5.100,00"],
			],
			footer: "",
		},
	},
	{
		id: 3,
		name: "Company C",
		logo: "https://img.logoipsum.com/224.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 4,
		name: "Company D",
		logo: "https://img.logoipsum.com/225.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 5,
		name: "Company E",
		logo: "https://img.logoipsum.com/226.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 6,
		name: "Company F",
		logo: "https://img.logoipsum.com/227.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 7,
		name: "Company G",
		logo: "https://img.logoipsum.com/228.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 8,
		name: "Company H",
		logo: "https://img.logoipsum.com/229.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 9,
		name: "Company I",
		logo: "https://img.logoipsum.com/230.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 10,
		name: "Company J",
		logo: "https://img.logoipsum.com/231.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 11,
		name: "Company K",
		logo: "https://img.logoipsum.com/232.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 12,
		name: "Company L",
		logo: "https://img.logoipsum.com/233.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 13,
		name: "Company M",
		logo: "https://img.logoipsum.com/234.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 14,
		name: "Company N",
		logo: "https://img.logoipsum.com/235.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 15,
		name: "Company O",
		logo: "https://img.logoipsum.com/236.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 16,
		name: "Company P",
		logo: "https://img.logoipsum.com/237.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 17,
		name: "Company Q",
		logo: "https://img.logoipsum.com/238.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 18,
		name: "Company R",
		logo: "https://img.logoipsum.com/239.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 19,
		name: "Company S",
		logo: "https://img.logoipsum.com/240.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
	{
		id: 20,
		name: "Company T",
		logo: "https://img.logoipsum.com/241.svg",
		priceList: {
			header: [],
			body: [],
			footer: "",
		},
	},
];

const PriceList = () => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [modalData, setModalData] = React.useState(null);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Modal
				text="Izaberite tip fajla za export."
				isOpen={isModalOpen}
				handleClose={closeModal}
				data={modalData}
			/>
			<PriceListContainer>
				{sampleData.map((item) => (
					<PriceListCard
						key={item.id}
						onClick={() => {
							setModalData(item);
							openModal();
						}}
					>
						<Content>
							<img
								src={item.logo}
								alt="logo"
								width={100}
							></img>
							<span>{item.name}</span>
						</Content>
					</PriceListCard>
				))}
			</PriceListContainer>
		</>
	);
};

export default PriceList;
