/** @format */

export const MRT_Localization_SR_LATN_RS = {
	actions: "Akcije",
	and: "i",
	cancel: "Otkaži",
	changeFilterMode: "Promeni režim filtriranja",
	changeSearchMode: "Promeni režim pretrage",
	clearFilter: "Poništi filter",
	clearSearch: "Poništi pretragu",
	clearSort: "Poništi sortiranje",
	clickToCopy: "Klikni da kopiraš",
	collapse: "Raširi",
	collapseAll: "Raširi sve",
	columnActions: "Akcije nad kolonama",
	copiedToClipboard: "Kopirani u clipboard",
	dropToGroupBy: "Ubaci u grupu po {column}",
	edit: "Izmeni",
	expand: "Proširi",
	expandAll: "Proširi sve",
	filterArrIncludes: "Uključuje",
	filterArrIncludesAll: "Uključuje sve",
	filterArrIncludesSome: "Uključuje",
	filterBetween: "Između",
	filterBetweenInclusive: "Inkluzivno između",
	filterByColumn: "Filtriraj po {column}",
	filterContains: "Sadrži",
	filterEmpty: "Prazno",
	filterEndsWith: "Završava se na",
	filterEquals: "Jednako",
	filterEqualsString: "Jednako",
	filterFuzzy: "Fazi",
	filterGreaterThan: "Veće",
	filterGreaterThanOrEqualTo: "Veće ili jednako",
	filterInNumberRange: "Između",
	filterIncludesString: "Sadrži",
	filterIncludesStringSensitive: "Sadrži",
	filterLessThan: "Manje",
	filterLessThanOrEqualTo: "Manje ili jednako",
	filterMode: "Režim filtriranja: {filterType}",
	filterNotEmpty: "Nije prazno",
	filterNotEquals: "Nije jednako",
	filterStartsWith: "Počinje sa",
	filterWeakEquals: "Jednako",
	filteringByColumn: "Filtriranje po {column} - {filterType} {filterValue}",
	goToFirstPage: "Idi na prvu stranicu",
	goToLastPage: "Idi na poslednju stranicu",
	goToNextPage: "Idi na sledeću stranicu",
	goToPreviousPage: "Idi na prošlu stranicu",
	grab: "Zgrabi",
	groupByColumn: "Grupiši po {column}",
	groupedBy: "Grupisano po ",
	hideAll: "Sakrij sve",
	hideColumn: "Sakrij {column} kolonu",
	max: "Max",
	min: "Min",
	move: "Pomeri",
	noRecordsToDisplay: "Nema redova za prikaz",
	noResultsFound: "Nema rezultata",
	of: "od",
	or: "ili",
	pinToLeft: "Zakači levo",
	pinToRight: "Zakači desno",
	resetColumnSize: "Resetuj širinu kolone",
	resetOrder: "Resetuj poredak",
	rowActions: "Akcije nad redovima",
	rowNumber: "#",
	rowNumbers: "Broj redova",
	rowsPerPage: "Redova po stranici",
	save: "Snimi",
	search: "Pretraži",
	selectedCountOfRowCountRowsSelected: "{selectedCount} od {rowCount} redova označeno",
	select: "Označi",
	showAll: "Prikaži sve",
	showAllColumns: "Prikaži sve kolone",
	showHideColumns: "Prikaži/sakrij kolone",
	showHideFilters: "Prikaži/sakrij filtere",
	showHideSearch: "Prikaži/sakrij pretragu",
	sortByColumnAsc: "Sortiraj po {column} rastuće",
	sortByColumnDesc: "Sortiraj po {column} opadajuće",
	sortedByColumnAsc: "Sortirano po {column} rastuće",
	sortedByColumnDesc: "Sortirano po {column} opadajuće",
	thenBy: ", onda po ",
	toggleDensity: "Podesi gustinu",
	toggleFullScreen: "Uključi/isključi preko celog ekrana",
	toggleSelectAll: "Označi/odznači sve redove",
	toggleSelectRow: "Označi/odznači red",
	toggleVisibility: "Uključi/isključi vidljivost",
	ungroupByColumn: "Odgrupiši po {column}",
	unpin: "Otkači",
	unpinAll: "Otkači sve",
	unsorted: "Nesortirano",
};
