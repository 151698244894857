/** @format */

import React from "react";
import { SideDetailsContext } from "../contexts/SideDetailsContext";
import { useLocation } from "react-router-dom";

const OrderDetails = React.memo((props) => {
	const { contextValue } = React.useContext(SideDetailsContext);
	const { id, buyer, address, invoice_number, dateTimeStamp, status } = contextValue;

	const location = useLocation();

	return (
		<>
			<div className="sd-details sd-height sd-common">
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>{props.number.current}</b>
					</div>
					<div className="sd-details-row-right">
						#{location.pathname === "/" ? id : invoice_number}
					</div>
				</div>
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>Kupac:</b>
					</div>
					<div className="sd-details-row-right">{buyer}</div>
				</div>
				{location.pathname === "/" && (
					<div className="sd-details-row-wrapper">
						<div className="sd-details-row-left">
							<b>Status:</b>
						</div>
						<div className="sd-details-row-right">{status}</div>
					</div>
				)}
				<div className="sd-separator"></div>
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>{props.date.current}</b>
					</div>
					<div className="sd-details-row-right">
						{new Date(dateTimeStamp).toLocaleDateString("sr")}
					</div>
				</div>
				{location.pathname === "/invoices" && (
					<div className="sd-details-row-wrapper">
						<div className="sd-details-row-left">
							<b>Valuta:</b>
						</div>
						<div className="sd-details-row-right">{"zasad stoji prazno"}</div>
					</div>
				)}
				<div className="sd-details-row-wrapper">
					<div className="sd-details-row-left">
						<b>Adresa isporuke:</b>
					</div>
					<div className="sd-details-row-right">{address}</div>
				</div>
				<div className="sd-details-row-wrapper">
					{/* Ukupna vrednost porudzbine / iznos fakture */}
					<div className="sd-details-row-left">
						<b>{props.value.current}</b>
					</div>
					<div className="sd-details-row-right">
						{/* {price.toLocaleString("sr", {
							style: "currency",
							currency: "RSD",
							minimumFractionDigits: 2,
						})} */}
						{location.pathname === "/"
							? sessionStorage.getItem("total_value")
							: "zasad stoji prazno"}
					</div>
				</div>
			</div>
		</>
	);
});

export default OrderDetails;
