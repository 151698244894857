/** @format */

import styled from "styled-components";

const InfoCardContainer = styled.div`
	display: flex;
	justify-content: space-evenly;
`;

const Card = styled.div`
	border: 1px solid red;
	border-radius: 5px;
	padding: 10px;
	/* box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1); */
`;

const Title = styled.h3`
	color: red;
	margin-top: 0;
`;

const Number = styled.p`
	color: blue;
	font-weight: bold;
	font-size: 2rem;
	margin: 0;
`;

export default function InfoCards({ cardsData }) {
	return (
		<>
			<InfoCardContainer>
				{cardsData.map((card, index) => (
					<Card key={index}>
						<Title>{card.title}</Title>
						<Number>{card.number}</Number>
					</Card>
				))}
			</InfoCardContainer>
		</>
	);
}
