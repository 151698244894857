/** @format */

import React from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import logo from "../images/logo_CH_black.png";
import Button from "@mui/material/Button";

import { SideDetailsContext } from "../contexts/SideDetailsContext";

export default function App() {
	const { contextValue } = React.useContext(SideDetailsContext);
	const { order } = contextValue;

	const xlsFileName = () => {
		const date = new Date(contextValue.dateTimeStamp).toLocaleDateString("sr").slice(0, -1);
		const fileName = `${contextValue.buyer} - ${date} - ${contextValue.id}`;
		return fileName;
	};

	const workSheetName = "Detalji porudžbine";

	// HEADER
	const header = {
		tr: "Tekući računi: 265-1100310000387-52, Raiffeisen, 220-159317-60 ProCredit,",
		otp: "250-1530000395030-72 Eurobanka, 325-9500500368445-79 OTP",
		tel: "Kontakt telefoni: +381 30/ 570-930; 570-903; 570-100",
		email: "E-mail: office@central-ch.com",
		uuk: "Ukupan upisan kapital: 979.276,85 RSD (novčani: 55.881,01 RSD + 923.395,84 RSD u osnovnim sredstvima)",
	};

	// UNDER HEADER
	const under_header = {
		ch: 'Privredno društvo: "Central-h" d.o.o. Majdanpek - jednočlano društvo sa ograničenom odgovornošću. Čekić bb, Debeli lug, 19250 Majdanpek.',
		pib: "PIB: 100987249 | Matični broj: 17122002 | Šifra delatnosti: 4674",
	};

	// Excel render & export
	const workbook = new Excel.Workbook();

	const saveExcel = async () => {
		try {
			const fileName = xlsFileName();

			// creating one worksheet in workbook
			const worksheet = workbook.addWorksheet(workSheetName);

			/********************** */
			/* H E A D E R  T E X T */
			/********************** */

			// set alignment to 'right'
			for (let i = 1; i <= 4; i++) {
				worksheet.getCell("D" + i).alignment = { horizontal: "right" };
			}

			worksheet.getRow(6).alignment = { vertical: "top", horizontal: "right" };

			// merge cells
			worksheet.mergeCells(1, 4, 1, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(2, 4, 2, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(3, 4, 3, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(4, 4, 4, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(6, 4, 6, 11); // (from row, from column, to row, to column)

			// adding header text
			worksheet.getCell("D1").value = header.tr;
			worksheet.getCell("D2").value = header.otp;
			worksheet.getCell("D3").value = header.tel;
			worksheet.getCell("D4").value = header.email;
			worksheet.getCell("D6").value = header.uuk;

			// change font size for rows 1-5
			for (let i = 1; i <= 5; i++) {
				for (let j = 4; j <= 11; j++) {
					let cell = worksheet.getCell(i, j);
					cell.font = {
						name: "DejaVu Sans Mono",
						size: 9,
					};
				}
			}

			// change fontsize for 6th row
			const fifthRow = worksheet.getRow(6);
			fifthRow.eachCell((cell) => {
				cell.font = { name: "DejaVu Sans Mono", size: 7 };
			});

			/************************ */
			/* U N D E R  H E A D E R */
			/************************ */

			// adding under header text
			worksheet.getCell("A8").value = under_header.ch;
			worksheet.getCell("A9").value = under_header.pib;

			// merge cells
			worksheet.mergeCells(8, 1, 8, 11);
			worksheet.mergeCells(9, 1, 9, 11);

			// set alignment to 'center'
			worksheet.getRow(8).alignment = {
				vertical: "bottom",
				horizontal: "center",
			};
			worksheet.getRow(9).alignment = {
				vertical: "top",
				horizontal: "center",
			};

			worksheet.getRow(8).height = 15;
			worksheet.getRow(9).height = 15;

			// fill with black background
			function setCellFill(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "FF000000" },
				};
			}
			setCellFill(worksheet, "A8");
			setCellFill(worksheet, "A9");

			// change font size and color
			function setFontColorSize(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.font = {
					color: { argb: "FFCFCFCF" },
				};
				cell.font = {
					name: "DejaVu Sans Mono",
					size: 7.5,
				};
			}
			setFontColorSize(worksheet, "A8");
			setFontColorSize(worksheet, "A9");

			// change fontsize for rows 12 - 16
			function setRowFontAndSize(worksheet, rowNumber, loopTime, fontName, fontSize, bold) {
				for (let i = 0; i < loopTime; i++) {
					const row = worksheet.getRow(rowNumber + i);
					row.eachCell((cell) => {
						cell.font = { name: fontName, size: fontSize, bold: bold };
					});
				}
			}
			// setRowFontAndSize(worksheet, 14, 5, "DejaVu Sans Mono", 9, true);

			// removing the bold for the details data
			worksheet.getCell("D14").font = {
				name: "DejaVu Sans Mono",
				size: 9,
				bold: false,
			};
			worksheet.getCell("D15").font = {
				name: "DejaVu Sans Mono",
				size: 9,
				bold: false,
			};
			worksheet.getCell("D16").font = {
				name: "DejaVu Sans Mono",
				size: 9,
				bold: false,
			};

			// align cells to right
			// const alignCellToRight = (cell) => {
			// 	cell.alignment = { horizontal: "center" };
			// };
			// alignCellToRight(worksheet.getCell("I13"));
			// alignCellToRight(worksheet.getCell("I14"));
			// alignCellToRight(worksheet.getCell("I15"));

			// worksheet.getCell("I13").font = {
			// 	name: "DejaVu Sans Mono",
			// 	size: 12,
			// 	bold: true,
			// };
			// worksheet.getCell("I14").font = {
			// 	name: "DejaVu Sans Mono",
			// 	size: 9,
			// 	bold: false,
			// };
			// worksheet.getCell("I15").font = {
			// 	name: "DejaVu Sans Mono",
			// 	size: 9,
			// 	bold: false,
			// };

			// fill with grey background
			function fillGrey(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "FFCCCCCC" },
				};
			}
			// fillGrey(worksheet, "I12");
			// fillGrey(worksheet, "I13");
			// fillGrey(worksheet, "I14");
			// fillGrey(worksheet, "I15");
			// fillGrey(worksheet, "I16");

			// fit the size of the cell to the text length
			// worksheet.getColumn('A').width = header.address.length;

			// Add image to cell A2
			const response = await fetch(logo);
			const imageData = await response.blob();
			const imageId = workbook.addImage({
				buffer: imageData,
				extension: "png",
			});

			// worksheet.addImage(imageId, 'A1:D6');

			worksheet.addImage(imageId, {
				tl: { col: 0, row: 0 },
				br: { col: 4, row: 6.5 },
				editAs: "oneCell", // or 'absolute'
			});

			/**
			 * TITLE
			 */
			worksheet.getCell("E12").value = "Pregled fakture";
			setRowFontAndSize(worksheet, 12, 1, "DejaVu Sans Mono", 12, true);

			/*********** */
			/* T A B L E */
			/*********** */

			const column_letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

			// table header
			order.header.forEach((item, index) => {
				if (index < 3) {
					worksheet.getCell(column_letters[index] + 15).value = item;
				} else {
					worksheet.getCell(column_letters[index + 1] + 15).value = item;
				}
			});

			// fill with grey background
			column_letters.forEach((letter) => {
				fillGrey(worksheet, letter + "15");
			});

			// table data
			let name_lenght = 0;
			order.body.forEach((row, row_index) => {
				row.forEach((item, index) => {
					if (index < 3) {
						// index here is 3 because we are skipping 3rd column, merging it with 4th, to make room for text
						worksheet.getCell(column_letters[index] + (16 + row_index)).value = item;
					} else {
						worksheet.getCell(column_letters[index + 1] + (16 + row_index)).value = item;
					}
				});

				// merge cells for column 'Naziv'
				worksheet.mergeCells(row_index + 15, 3, row_index + 15, 4); // (from row, from column, to row, to column)

				// fit the size of the 'Naziv' column's cells to the text length
				if (name_lenght < row[2].length) name_lenght = row[2].length;
				// worksheet.getColumn('D').width = name_lenght;
				worksheet.getColumn("D").width = name_lenght - (name_lenght * 25) / 100;

				// fit the size of the 'Vrednost' column's cells to the text length
				let value = 0;
				if (value < row[9].length) value = row[9].length;
				worksheet.getColumn("K").width = value + 3;

				// align left A & B column
				worksheet.getCell(`A${row_index + 16}`).alignment = { horizontal: "left" };
				worksheet.getCell(`B${row_index + 16}`).alignment = { horizontal: "left" };

				// set fonts or the table
				setRowFontAndSize(
					worksheet,
					row_index + 16,
					order.body.length,
					"DejaVu Sans Mono",
					9,
					false,
				);
			});

			// table footer
			// fill with grey background
			column_letters.forEach((letter) => {
				fillGrey(worksheet, letter + (order.body.length + 16));
			});

			// beneath the table
			const start_of_totals = order.body.length + 16 + 3; // 16 is the start of dynamic data, 3 is the number of rows beneath the table
			worksheet.getCell(`I${start_of_totals}`).value = "Ukupno bez PDV-a:";
			worksheet.getCell(`I${start_of_totals + 1}`).value = "Iznos PDV-a:";
			worksheet.getCell(`I${start_of_totals + 2}`).value = "Ukupno sa PDV-om:";

			function performCalculations(order_values) {
				let sumValues = 0;

				order_values.forEach((array) => {
					const lastIndex = array[array.length - 1];
					const index9Value = parseFloat(lastIndex.replace(/[^\d,-]/g, "").replace(",", "."));
					sumValues += index9Value;
				});

				const valueWithPdv = sumValues.toLocaleString("sr-RS", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				const lastIndexValue = parseFloat(
					order_values[0][7].replace(/[^\d,-]/g, "").replace(",", "."),
				);
				const valueOnly = (sumValues / (1 + lastIndexValue / 100)).toLocaleString("sr-RS", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
				const pdvOnly = (
					(sumValues / (1 + lastIndexValue / 100)) *
					(lastIndexValue / 100)
				).toLocaleString("sr-RS", {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});

				return [valueWithPdv, valueOnly, pdvOnly];
			}

			const valueWithPdv = performCalculations(order.body)[0];
			const valueOnly = performCalculations(order.body)[1];
			const pdvOnly = performCalculations(order.body)[2];

			worksheet.getCell(`K${start_of_totals}`).value = valueOnly;
			worksheet.getCell(`K${start_of_totals + 1}`).value = pdvOnly;
			worksheet.getCell(`K${start_of_totals + 2}`).value = valueWithPdv;

			worksheet.getCell(`K${start_of_totals}`).alignment = { horizontal: "right" };
			worksheet.getCell(`K${start_of_totals + 1}`).alignment = { horizontal: "right" };
			worksheet.getCell(`K${start_of_totals + 2}`).alignment = { horizontal: "right" };

			// set the font for the table
			setRowFontAndSize(worksheet, 15, 1, "DejaVu Sans Mono", 9, true);

			// export excel file
			// write the content using writeBuffer
			const buf = await workbook.xlsx.writeBuffer().then((data) => data);

			// download the processed file
			saveAs(new Blob([buf]), `${fileName}.xlsx`);
		} catch (error) {
			console.error("<<<ERRROR>>>", error);
			console.error("Something Went Wrong", error.message);
		} finally {
			// removing worksheet's instance to create new one
			workbook.removeWorksheet(workSheetName);
		}
	};

	return (
		<div>
			{/* <input
				type='text'
				id={myInputId}
			/> */}
			{/* NAME OF THE FILE SHOULD BE DYNAMIC */}
			<Button
				className="xls-btn"
				variant="contained"
				onClick={saveExcel}
			>
				.xls
			</Button>
		</div>
	);
}
