/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFexportPriceList from "../pdf_&_excel/PDFexportPriceList";
import ExcelExportPriceList from "../pdf_&_excel/ExcelExportPriceList";

const style = {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	borderRadius: 2,
	p: 4,
	color: "black",
};

const pdfFileName = (companyName) => {
	return `${companyName}_CH_cenovnik.pdf`;
};

export default function BasicModal({ text, isOpen, handleClose, data }) {
	return (
		<div>
			<Modal
				open={isOpen}
				onClose={handleClose}
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{text}
					</Typography>
					<Typography
						id="modal-modal-description"
						sx={{ mt: 2 }}
					>
						<PDFDownloadLink
							document={<PDFexportPriceList data={data} />}
							fileName={pdfFileName(data?.name)}
						>
							{({ loading }) =>
								loading ? (
									"..."
								) : (
									<Button
										onClick={() => {
											setTimeout(() => {
												handleClose();
											});
										}}
										style={{ backgroundColor: "red", color: "white", marginRight: "25px" }}
									>
										.pdf
									</Button>
								)
							}
						</PDFDownloadLink>
						<ExcelExportPriceList data={data} />
					</Typography>
				</Box>
			</Modal>
		</div>
	);
}
