export const rows = [
	{
		id: 1, // this is the id of the invoice
		buyer: 'Central-H',
		address: 'testing this out...',
		dateTimeStamp: '2022-09-13T10:01:08+0200',
		payment_due_date: '2022-09-13T10:01:08+0200',
		invoice_number: '122022',
		amount: "16.750,80",
		paid: "16.750,80",
		unpaid: "0,00",
		status: 'Plaćeno',
		color: 'success',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[1, 509015, "Test koleno 91 fi1", "kom", "846,00", "22,00", "25,00%", "20,00%", "16,50", "16.750,80"],
				[2, 509016, "Dvodelno koleno 99 fi2", "kom", "1.593,00", "94,00", "25,00%", "20,00%", "70,50", "134.767,80"],
				[3, 509017, "Trodelno koleno 99 fi3", "kom", "887,00", "14,00", "25,00%", "20,00%", "10,50", "11.176,20"],
				[4, 509018, "A ovo?", "kom", "100,00", "10,00", "25,00%", "20,00%", "10,50", "1.111,11"],
			],
			footer: '',
		},
	},
];
