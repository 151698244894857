/** @format */

import React from "react";
import "./App.css";

import logoImage from "./images/logo_CH.svg";
import MainContent from "./components/MainContent";
import Menu from "./components/Menu";
import SideDetails from "./components/SideDetails";
import { SideDetailsContext } from "./contexts/SideDetailsContext";
import { SideDetailsAnimateContext } from "./contexts/SideDetailsAnimateContext";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

const MenuButton = styled(MuiButton)(({ theme }) => ({
	position: "absolute",
	bottom: "90px",
	right: "20px",
	zIndex: 500,
	fontWeight: "bold",
	height: "60px",
	width: "50px",
	borderRadius: "50%",
	backgroundColor: "red",
	// backgroundColor: theme.palette.success.main,
	"&: hover": {
		backgroundColor: "red",
		// backgroundColor: theme.palette.success.main,
	},
}));

function App() {
	//initial context value, to avoid error when context is used before it is defined
	const [contextValue, setContextValue] = React.useState({
		id: 1,
		buyer: "John Doe",
		address: "123 Main St",
		dateTimeStamp: "2020-01-01 12:00:00",
		price: 100,
	});

	const providerValue = React.useMemo(
		() => ({ contextValue, setContextValue }),
		[contextValue, setContextValue],
	);

	const sideDetails_wrapper = React.useRef(null);

	const [isMounted, setIsMounted] = React.useState(false);

	function animateIn() {
		sideDetails_wrapper.current.classList.add("animate-sideDetails");
	}
	function animateOut() {
		sideDetails_wrapper.current.classList.remove("animate-sideDetails");
	}

	React.useEffect(() => {
		//this is to avoid the animation on the first render
		if (isMounted) {
			animateIn();
		} else {
			setIsMounted(true);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contextValue]);

	const [addClass, setAddClass] = React.useState(false);

	const toggleClass = () => {
		setAddClass(prevAddClass => !prevAddClass);
	};

	return (
		<div className="ch-a-container">
			{/* MENU / Left Side */}
			<div className={`ch-side-left ${addClass ? 'added' : ''}`}>
				<img
					src={logoImage}
					alt="logo CH"
					style={{
						width: "100%",
						height: "90px",
						objectFit: "cover",
						// zIndex: 4,
					}}
				/>
				<div className="ch-search" />
				<div className="ch-menu-wrapp scroll-handler">
					<Menu />
				</div>
			</div>

			{/* MAIN CONTENT */}
			<SideDetailsAnimateContext.Provider value={animateOut}>
				<SideDetailsContext.Provider value={providerValue}>
					<div>
						<MenuButton
							variant="contained"
							className="ch-menu-toggle"
							onClick={toggleClass}
						>
							Meni
						</MenuButton>
					</div>
					<div className="ch-content-right">
						<MainContent />
					</div>
					<div
						ref={sideDetails_wrapper}
						className="sd-details-wrapper"
					>
						<SideDetails />
					</div>
				</SideDetailsContext.Provider>
			</SideDetailsAnimateContext.Provider>
		</div>
	);
}

export default App;
