export const rows = [
	{
		id: 1,
		date: '2022-09-13T10:01:08+0200',
		payment_due_date: '2022-09-13T10:01:08+0200',
		owes_amount: "16.750,80",
		demands_amount: "16.750,80",
		document_number: '122022',
	},
];
