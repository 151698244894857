/** @format */

import React from "react";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import logo from "../images/logo_CH_black.png";
import Button from "@mui/material/Button";
// import { fileName } from "../utils/pdf_excel_export_fileNaming";

export default function App(data) {
	// TODO: make use of fileName util function instead of this
	const xlsFileName = () => {
		// FILE NAME
		const currentDate = new Date();
		const month = currentDate.getMonth() + 1;
		const formattedMonth = month < 10 ? `0${month}` : month;
		const formattedDate = `${currentDate.getDate()}.${formattedMonth}.${currentDate.getFullYear()}`;
		const fileName = `VP cenovnik - ${data?.data?.name} - ${formattedDate}`;

		return fileName;
	};

	const workSheetName = "VP cenovnik";

	// HEADER
	const header = {
		tr: "Tekući računi: 265-1100310000387-52, Raiffeisen, 220-159317-60 ProCredit,",
		otp: "250-1530000395030-72 Eurobanka, 325-9500500368445-79 OTP",
		tel: "Kontakt telefoni: +381 30/ 570-930; 570-903; 570-100",
		email: "E-mail: office@central-ch.com",
		uuk: "Ukupan upisan kapital: 979.276,85 RSD (novčani: 55.881,01 RSD + 923.395,84 RSD u osnovnim sredstvima)",
	};

	// UNDER HEADER
	const under_header = {
		ch: 'Privredno društvo: "Central-h" d.o.o. Majdanpek - jednočlano društvo sa ograničenom odgovornošću. Čekić bb, Debeli lug, 19250 Majdanpek.',
		pib: "PIB: 100987249 | Matični broj: 17122002 | Šifra delatnosti: 4674",
	};

	// Excel render & export
	const workbook = new Excel.Workbook();

	const saveExcel = async () => {
		try {
			const fileName = xlsFileName();

			// creating one worksheet in workbook
			const worksheet = workbook.addWorksheet(workSheetName);

			// ! H E A D E R  T E X T

			// set alignment to 'right'
			for (let i = 1; i <= 4; i++) {
				worksheet.getCell("D" + i).alignment = { horizontal: "right" };
			}

			worksheet.getRow(6).alignment = { vertical: "top", horizontal: "right" };

			// merge cells
			worksheet.mergeCells(1, 4, 1, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(2, 4, 2, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(3, 4, 3, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(4, 4, 4, 11); // (from row, from column, to row, to column)
			worksheet.mergeCells(6, 4, 6, 11); // (from row, from column, to row, to column)

			// adding header text
			worksheet.getCell("D1").value = header.tr;
			worksheet.getCell("D2").value = header.otp;
			worksheet.getCell("D3").value = header.tel;
			worksheet.getCell("D4").value = header.email;
			worksheet.getCell("D6").value = header.uuk;

			// change font size for rows 1-5
			for (let i = 1; i <= 5; i++) {
				for (let j = 4; j <= 11; j++) {
					let cell = worksheet.getCell(i, j);
					cell.font = {
						name: "DejaVu Sans Mono",
						size: 9,
					};
				}
			}

			// change fontsize for 6th row
			const fifthRow = worksheet.getRow(6);
			fifthRow.eachCell((cell) => {
				cell.font = { name: "DejaVu Sans Mono", size: 7 };
			});

			// ! U N D E R  H E A D E R

			// adding under header text
			worksheet.getCell("A8").value = under_header.ch;
			worksheet.getCell("A9").value = under_header.pib;

			// merge cells
			worksheet.mergeCells(8, 1, 8, 11);
			worksheet.mergeCells(9, 1, 9, 11);

			// set alignment to 'center'
			worksheet.getRow(8).alignment = {
				vertical: "bottom",
				horizontal: "center",
			};
			worksheet.getRow(9).alignment = {
				vertical: "top",
				horizontal: "center",
			};

			worksheet.getRow(8).height = 15;
			worksheet.getRow(9).height = 15;

			// fill with black background
			function setCellFill(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "FF000000" },
				};
			}
			setCellFill(worksheet, "A8");
			setCellFill(worksheet, "A9");

			// change font size and color
			function setFontColorSize(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.font = {
					color: { argb: "FFCFCFCF" },
				};
				cell.font = {
					name: "DejaVu Sans Mono",
					size: 7.5,
				};
			}
			setFontColorSize(worksheet, "A8");
			setFontColorSize(worksheet, "A9");

			// * [ UTIL FUNCTIONS ]
			// change fontsize for rows
			function setRowFontAndSize(worksheet, rowNumber, loopTime, fontName, fontSize, bold) {
				for (let i = 0; i < loopTime; i++) {
					const row = worksheet.getRow(rowNumber + i);
					row.eachCell((cell) => {
						cell.font = { name: fontName, size: fontSize, bold: bold };
					});
				}
			}

			// // merge cells
			// const mergeCells = (startRow, startCol, endRow, endCol) => {
			// 	worksheet.mergeCells(startRow, startCol, endRow, endCol);
			// };

			// // align cells to right
			// const alignCellToRight = (cell) => {
			// 	cell.alignment = { horizontal: "center" };
			// };

			// fill with grey
			function fillGrey(worksheet, cellNumber) {
				const cell = worksheet.getCell(cellNumber);
				cell.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "FFCCCCCC" },
				};
			}
			// * [ END UTIL FUNCTIONS ]

			// TODO: check if these font settings are needed
			worksheet.getCell("I13").font = {
				name: "DejaVu Sans Mono",
				size: 12,
				bold: true,
			};
			worksheet.getCell("I14").font = {
				name: "DejaVu Sans Mono",
				size: 9,
				bold: false,
			};
			worksheet.getCell("I15").font = {
				name: "DejaVu Sans Mono",
				size: 9,
				bold: false,
			};

			// Add image to cell A2
			const response = await fetch(logo);
			const imageData = await response.blob();
			const imageId = workbook.addImage({
				buffer: imageData,
				extension: "png",
			});

			// * another way of adding an image => worksheet.addImage(imageId, 'A1:D6');
			worksheet.addImage(imageId, {
				tl: { col: 0, row: 0 },
				br: { col: 4, row: 6.5 },
				editAs: "oneCell", // or 'absolute'
			});

			// title of the document
			worksheet.getCell("B11").value = xlsFileName();

			// ! T A B L E

			const column_letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

			// table header
			data.data.priceList.header.forEach((item, index) => {
				/**
				 * this index here sets one column to the right when index of the data is higher than 3,
				 * that way there is enough space for the text[Šifra artikla] to fit in two columns
				 */
				if (index < 3) {
					worksheet.getCell(column_letters[index + 1] + 13).value = item;
				} else {
					worksheet.getCell(column_letters[index + 2] + 13).value = item;
				}
			});

			// fill a column with grey background
			column_letters.forEach((letter) => {
				fillGrey(worksheet, letter + "13");
			});

			// table data
			data.data.priceList.body.forEach((row, row_index) => {
				row.forEach((item, index) => {
					if (index < 3) {
						worksheet.getCell(column_letters[index + 1] + (14 + row_index)).value = item;
					} else {
						worksheet.getCell(column_letters[index + 2] + (14 + row_index)).value = item;
					}
				});

				// fit the size of the 'Naziv' (indexed at 3) column's cells to the text length, plus a bit more
				let value = 0;
				if (value < row[3].length) value = row[3].length;
				worksheet.getColumn("F").width = value + 3;

				// align left A & B column
				worksheet.getCell(`A${row_index + 14}`).alignment = { horizontal: "left" };
				worksheet.getCell(`B${row_index + 14}`).alignment = { horizontal: "left" };

				// set fonts or the table body using util function
				setRowFontAndSize(
					worksheet,
					row_index + 14,
					data.data.priceList.body.length,
					"DejaVu Sans Mono",
					9,
					false,
				);
			});

			// set the font for the table using util function
			setRowFontAndSize(worksheet, 13, 1, "DejaVu Sans Mono", 9, true);

			// export excel file
			// write the content using writeBuffer
			const buf = await workbook.xlsx.writeBuffer().then((data) => data);

			// download the processed file
			saveAs(new Blob([buf]), `${fileName}.xlsx`);
		} catch (error) {
			console.error("Something Went Wrong", error.message);
		} finally {
			// remove worksheet's instance to create new one
			workbook.removeWorksheet(workSheetName);
		}
	};

	return (
		<>
			<Button
				className="xls-btn"
				variant="contained"
				onClick={saveExcel}
			>
				.xls
			</Button>
		</>
	);
}
