export const lines = [
	{
		"text": "Grejanje",
		"icon": "fireplace",
		"families": [
			{
				"name": "Bakarne cevi",
				"groups": [
					{
						"id": 1,
						"name": "Bakarne cevi - prave tvrde 5m"
					},
					{
						"id": 2,
						"name": "Bakarne cevi - prave polutvrde 5m"
					},
					{
						"id": 3,
						"name": "Bakarne cevi - Industrijske"
					},
					{
						"id": 4,
						"name": "Bakarne cevi - PVC"
					},
					{
						"id": 5,
						"name": "Bakarne cevi - Cu Smart"
					},
					{
						"id": 6,
						"name": "Bakarne cevi - Talos Dual"
					},
					{
						"id": 7,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Višeslojne i plastične cevi",
				"groups": [
					{
						"id": 8,
						"name": "Višeslojne i plastične cevi - COMAP"
					},
					{
						"id": 9,
						"name": "Višeslojne i plastične cevi - KAN THERM"
					},
					{
						"id": 10,
						"name": "Višeslojne i plastične cevi - WEGA"
					},
					{
						"id": 11,
						"name": "Višeslojne i plastične cevi - REHAU"
					},
					{
						"id": 12,
						"name": "Višeslojne i plastične cevi - UPONOR"
					},
					{
						"id": 13,
						"name": "Višeslojne i plastične cevi - PEŠTAN"
					},
					{
						"id": 14,
						"name": "Višeslojne i plastične cevi  - OSTALO"
					}
				]
			},
			{
				"name": "Čelicne cevi",
				"groups": [
					{
						"id": 15,
						"name": "Čelične cevi (crne)"
					},
					{
						"id": 16,
						"name": "Čelične galvanizovane cevi "
					}
				]
			},
			{
				"name": "Fiting",
				"groups": [
					{
						"id": 17,
						"name": "Bakarni fiting - EU"
					},
					{
						"id": 18,
						"name": "Bakarni fiting - (S)"
					},
					{
						"id": 19,
						"name": "Bakarni fiting - (KTM)"
					},
					{
						"id": 20,
						"name": "Bakarni fiting - KEPO"
					},
					{
						"id": 21,
						"name": "Bakarni pres fiting fiting - IBP"
					},
					{
						"id": 22,
						"name": "Mesingani fiting (prelazi) - KOFORM"
					},
					{
						"id": 23,
						"name": "Mesingani fiting (prelazi) - UVOZ"
					},
					{
						"id": 24,
						"name": "Mesingani fiting (prelazi) - RAZNO"
					},
					{
						"id": 25,
						"name": "Bronzani fiting"
					},
					{
						"id": 26,
						"name": "Bronzani fiting - VIEGA"
					},
					{
						"id": 27,
						"name": "Pres fiting - COMAP"
					},
					{
						"id": 28,
						"name": "Pres fiting - IBP"
					},
					{
						"id": 29,
						"name": "PRES FITING - UPONOR"
					},
					{
						"id": 30,
						"name": "Pres fiting - RAZNO"
					},
					{
						"id": 31,
						"name": "Kompresioni fiting - COMAP"
					},
					{
						"id": 32,
						"name": "Kompresioni fiting - KINA"
					},
					{
						"id": 33,
						"name": "Kompresioni fiting Cu - Jevco"
					},
					{
						"id": 34,
						"name": "Kompresioni fiting - TIEMME"
					},
					{
						"id": 35,
						"name": "Kompresioni fiting - STRATO PLUS"
					},
					{
						"id": 36,
						"name": "Galvanizovani fiting"
					},
					{
						"id": 37,
						"name": "Čelični fiting (crni)"
					},
					{
						"id": 38,
						"name": "Čelični fiting crni - RAZNO"
					},
					{
						"id": 39,
						"name": "Neaktivne pozicije"
					},
					{
						"id": 40,
						"name": "Radijatorski ventili  i oprema - COMAP"
					}
				]
			},
			{
				"name": "Armatura",
				"groups": [
					{
						"id": 41,
						"name": "Radijatorski ventili  i oprema - COMAP"
					},
					{
						"id": 42,
						"name": "Termostatski setovi  - COMAP"
					},
					{
						"id": 43,
						"name": "Radijatorski ventili i oprema - HEIMEIER"
					},
					{
						"id": 44,
						"name": "Termostatski setovi  - HEIMEIER"
					},
					{
						"id": 45,
						"name": "Radijatorski ventili i oprema  - CALEFFI"
					},
					{
						"id": 46,
						"name": "Radijatorski ventili i oprema  - KRT DELFIN"
					},
					{
						"id": 47,
						"name": "Radijatorski ventili i oprema  - HERZ"
					},
					{
						"id": 48,
						"name": "Radijatorski ventili i oprema  - OVENTROP"
					},
					{
						"id": 49,
						"name": "Radijatorski ventili i oprema  - TIEMME"
					},
					{
						"id": 50,
						"name": "Radijatorski ventili i oprema  - RAZNO"
					},
					{
						"id": 51,
						"name": "Regulacioni ventili Ballorex – COMAP"
					},
					{
						"id": 52,
						"name": "Ventili i slavine - IBP"
					},
					{
						"id": 53,
						"name": "Ventili i slavine - LD"
					},
					{
						"id": 54,
						"name": "Ventili i slavine - SLOVARM"
					},
					{
						"id": 55,
						"name": "Ventili i slavine - FERRO"
					},
					{
						"id": 56,
						"name": "Ventili i slavine - AQUAWORLD"
					},
					{
						"id": 57,
						"name": "Ventili i slavine - RAZNO"
					},
					{
						"id": 58,
						"name": "Mešni i elektromotorni ventili, pogoni - ESBE"
					},
					{
						"id": 59,
						"name": "Mešni i elektromotorni ventili, pogoni - RAZNO"
					},
					{
						"id": 60,
						"name": "Oprema za čišćenje i odzračivanje"
					}
				]
			},
			{
				"name": "Oprema za podno grejanje",
				"groups": [
					{
						"id": 61,
						"name": "Oprema za podno grejanje Biofloor - COMAP"
					},
					{
						"id": 62,
						"name": "Oprema za podno grejanje - REHAU"
					},
					{
						"id": 63,
						"name": "Oprema za podno grejanje  - POLY"
					},
					{
						"id": 64,
						"name": "Oprema za podno grejanje  - UPONOR"
					},
					{
						"id": 65,
						"name": "Mesingani razdelnici i dodatna oprema - COMAP"
					},
					{
						"id": 66,
						"name": "Oprema za podno grejanje - ITAP"
					},
					{
						"id": 67,
						"name": "Oprema za podno grejanje - RAZNO"
					},
					{
						"id": 68,
						"name": "Ormarići za razdelnike - TERMOPOOL"
					},
					{
						"id": 69,
						"name": "Oramrići za razdelnike - RAZNO"
					},
					{
						"id": 70,
						"name": "Oprema za podno grejanje - KAN THERM"
					},
					{
						"id": 71,
						"name": "Ploča za podno grejanje"
					}
				]
			},
			{
				"name": "Kotlovi",
				"groups": [
					{
						"id": 72,
						"name": "Kotlovi - RADIJATOR INŽENJERING"
					},
					{
						"id": 73,
						"name": "Kotlovi - RADIJATOR INŽENJERING - Industrijski"
					},
					{
						"id": 74,
						"name": "Kotlovi - KEPO"
					},
					{
						"id": 75,
						"name": "Kotlovi - ALFA PLAM"
					},
					{
						"id": 76,
						"name": "Kotlovi - MEGAL"
					},
					{
						"id": 77,
						"name": "Kotlovi - ENRAD"
					},
					{
						"id": 78,
						"name": "Kotlovi - CENTROMETAL"
					},
					{
						"id": 79,
						"name": "Kotlovi - EKOTERM PROEKT"
					},
					{
						"id": 80,
						"name": "Kotlovi - TERMOMONT"
					},
					{
						"id": 81,
						"name": "Kotlovi - THERMOFLUX"
					},
					{
						"id": 82,
						"name": "Kotlovi - ŠUKOM"
					},
					{
						"id": 83,
						"name": "Kotlovi - MARELI"
					},
					{
						"id": 84,
						"name": "Kotlovi - PRITY"
					},
					{
						"id": 85,
						"name": "Kotlovi - ABC"
					},
					{
						"id": 86,
						"name": "Kotlovi - BLIST"
					},
					{
						"id": 87,
						"name": "Kotlovi - KEPO WOOD"
					},
					{
						"id": 88,
						"name": "Kotlovi - RAZNO"
					},
					{
						"id": 89,
						"name": "Liveni Kotlovi - VIADRUS"
					},
					{
						"id": 90,
						"name": "Liveni kotlovi - FERROLI"
					},
					{
						"id": 91,
						"name": "Liveni kotlovi - LOGAN"
					},
					{
						"id": 92,
						"name": "Električni kotlovi - EKOPAN DM"
					},
					{
						"id": 93,
						"name": "Električni kotlovi - MIKOTERM"
					},
					{
						"id": 94,
						"name": "Električni kotlovi - VAILLANT"
					},
					{
						"id": 95,
						"name": "Električni kotlovi - VIESSMANN"
					},
					{
						"id": 96,
						"name": "Električni kotlovi - IMPRO"
					},
					{
						"id": 97,
						"name": "Električni kotlovi - TRGOELEKTR0"
					},
					{
						"id": 98,
						"name": "Električni kotlovi - EKOPAN DM (Izvoz)"
					},
					{
						"id": 99,
						"name": "Gasni kotlovi - VAILLANT"
					},
					{
						"id": 100,
						"name": "Gasni kotlovi - ARISTON"
					},
					{
						"id": 101,
						"name": "Gasni kotlovi - VIESSMANN"
					},
					{
						"id": 102,
						"name": "Gasni kotlovi - PROTHERM"
					},
					{
						"id": 103,
						"name": "Gasni kotlovi - IMMERGAS"
					},
					{
						"id": 104,
						"name": "Gasni kotlovi - OSTALO"
					},
					{
						"id": 105,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Rezervni delovi i dodatna oprema za kotlove",
				"groups": [
					{
						"id": 106,
						"name": "Rezervni delovi i oprema za kotlove - RADIJATOR INŽENJERING"
					},
					{
						"id": 107,
						"name": "Rezervni delovi i oprema za kotlove - KEPO"
					},
					{
						"id": 108,
						"name": "Rezervni delovi i oprema za kotlove - ALFA PLAM"
					},
					{
						"id": 109,
						"name": "Rezervni delovi i oprema za kotlove - MEGAL"
					},
					{
						"id": 110,
						"name": "Rezervni delovi i oprema za kotlove - ENRAD"
					},
					{
						"id": 111,
						"name": "Rezervni delovi i oprema za kotlove - CENTROMETAL"
					},
					{
						"id": 112,
						"name": "Rezervni delovi i oprema za kotlove - EKOTERM PROEKT"
					},
					{
						"id": 113,
						"name": "Rezervni delovi i oprema za kotlove - VIADRUS"
					},
					{
						"id": 114,
						"name": "Rezervni delovi i oprema za kotlove - EKOPAN DM"
					},
					{
						"id": 115,
						"name": "Rezervni delovi i oprema za kotlove - MIKOTERM"
					},
					{
						"id": 116,
						"name": "Rezervni delovi i oprema za kotlove - VAILLANT"
					},
					{
						"id": 117,
						"name": "Rezervni delovi i oprema za kotlove - ARISTON"
					},
					{
						"id": 118,
						"name": "Rezervni delovi i oprema za kotlove - VIESSMANN"
					},
					{
						"id": 119,
						"name": "Rezervni delovi i oprema za kotlove - PROTHERM"
					},
					{
						"id": 120,
						"name": "Rezervni delovi i oprema za kotlove -IMMERGAS"
					},
					{
						"id": 121,
						"name": "Rezervni delovi i oprema za kotlove - RAZNO"
					},
					{
						"id": 122,
						"name": "Pretvarači napona - MIK"
					},
					{
						"id": 123,
						"name": "Pretvarači napona - MC"
					},
					{
						"id": 124,
						"name": "Regulatori promaje"
					},
					{
						"id": 125,
						"name": "Dodatna oprema i pribor za kotlove"
					},
					{
						"id": 126,
						"name": "Vatrostalne opeke"
					},
					{
						"id": 127,
						"name": "Upaljači za pelet - IEG"
					},
					{
						"id": 128,
						"name": "Kotlovski razdelnici i hidraulične skretnice - MIK MARING"
					},
					{
						"id": 129,
						"name": "Kotlovski razdelnici i hidraulične skretnice - MEIBES"
					},
					{
						"id": 130,
						"name": "Dodatna oprema i pribor za kotlove - RAZNO"
					},
					{
						"id": 131,
						"name": "Dodatna oprema za gasne kotlove - RAZNO"
					}
				]
			},
			{
				"name": "Antifriz",
				"groups": [
					{
						"id": 132,
						"name": "Antifriz - ADECO"
					},
					{
						"id": 133,
						"name": "Antifriz - N-ANT"
					},
					{
						"id": 134,
						"name": "Antifriz - AURORA"
					},
					{
						"id": 135,
						"name": "Antifriz - DINARA OIL"
					},
					{
						"id": 136,
						"name": "Antifriz - RAZNO"
					}
				]
			},
			{
				"name": "Radijatori",
				"groups": [
					{
						"id": 137,
						"name": "Radijatori panelni - ENRAD"
					},
					{
						"id": 138,
						"name": "Radijatori panelni - IMAS"
					},
					{
						"id": 139,
						"name": "Radijatori panelni - TERMOPAN"
					},
					{
						"id": 140,
						"name": "Radijatori panelni - PURMO"
					},
					{
						"id": 141,
						"name": "Radijatori panelni - RAZNO"
					},
					{
						"id": 142,
						"name": "Radijatori aluminijumski - GLOBAL"
					},
					{
						"id": 143,
						"name": "Radijatori aluminijumski - FERROLI"
					},
					{
						"id": 144,
						"name": "Radijatori aluminijumski - FARAL"
					},
					{
						"id": 145,
						"name": "Radijatori aluminijski - RAZNO"
					},
					{
						"id": 146,
						"name": "Radijatori cevasti - EURO"
					},
					{
						"id": 147,
						"name": "Radijatori cevasti - ENRAD"
					},
					{
						"id": 148,
						"name": "Radijatori cevasti - STARPAN"
					},
					{
						"id": 149,
						"name": "Radijatori cevasti - M&A"
					},
					{
						"id": 150,
						"name": "Radijatori cevasti - TERMAL TREND"
					},
					{
						"id": 151,
						"name": "Radijatori cevasti - ELEGANT"
					},
					{
						"id": 152,
						"name": "Radijatori cevasti - CINI"
					},
					{
						"id": 153,
						"name": "Radijatori cevasti - RAZNO"
					},
					{
						"id": 154,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Dodatna oprema za radijatore",
				"groups": [
					{
						"id": 155,
						"name": "Dodatna oprema za radijatore"
					},
					{
						"id": 156,
						"name": "Dodatna oprema za radijatore - KRT DELFIN"
					},
					{
						"id": 157,
						"name": "Grejači za radijatore - IEG"
					},
					{
						"id": 158,
						"name": "Grejači za radijatore - CINI"
					},
					{
						"id": 159,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Pumpe",
				"groups": [
					{
						"id": 160,
						"name": "Pumpe cirkulacione - WILO"
					},
					{
						"id": 161,
						"name": "Pumpe - GRUNDFOS"
					},
					{
						"id": 162,
						"name": "Pumpe - IMP"
					},
					{
						"id": 163,
						"name": "Pumpe - HST"
					},
					{
						"id": 164,
						"name": "Pumpe - WRS"
					},
					{
						"id": 165,
						"name": "Pumpe - Unacl"
					},
					{
						"id": 166,
						"name": "Pumpe - RAZNO"
					},
					{
						"id": 167,
						"name": "Pumpe OEM - WILO"
					},
					{
						"id": 168,
						"name": "Pumpne grupe - MEIBES"
					},
					{
						"id": 169,
						"name": "Pumpne grupe - ICMA"
					},
					{
						"id": 170,
						"name": "Pumpne grupe - HERZ"
					},
					{
						"id": 171,
						"name": "Pumpe - Pribor - RAZNO"
					},
					{
						"id": 172,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Oprema za kotlarnice i podstanice",
				"groups": [
					{
						"id": 173,
						"name": "Armatura za kotlarnice i podstanice"
					},
					{
						"id": 174,
						"name": "Ventili - sigurnosni"
					},
					{
						"id": 175,
						"name": "Hidraulične skretnice, razdelnici i pribor"
					},
					{
						"id": 176,
						"name": "Kalorimetri"
					},
					{
						"id": 177,
						"name": "Pumpne stanice"
					},
					{
						"id": 178,
						"name": "Izmenjivači toplote"
					},
					{
						"id": 179,
						"name": "Toplotne podstanice"
					}
				]
			},
			{
				"name": "Pribor za fiksiranje i kačenje",
				"groups": [
					{
						"id": 180,
						"name": "Šrafovska roba - OSTALO"
					}
				]
			},
			{
				"name": "Izolacije",
				"groups": [
					{
						"id": 181,
						"name": "Izolacija mineralna vuna"
					},
					{
						"id": 182,
						"name": "Dekorativna izolacija - HZ"
					},
					{
						"id": 183,
						"name": "Neaktivne pozicije"
					},
					{
						"id": 184,
						"name": "Izolacija cevi"
					}
				]
			},
			{
				"name": "Ekspanzione posude",
				"groups": [
					{
						"id": 185,
						"name": "Ekspanzione posude grejačke - BASEFLEX"
					},
					{
						"id": 186,
						"name": "Eskpanzione posude grejačke - VAREM"
					},
					{
						"id": 187,
						"name": "Ekspanzione posude grejačke - ELBI"
					},
					{
						"id": 188,
						"name": "Ekspanzione posude grejačke - AQUASYSTEM"
					},
					{
						"id": 189,
						"name": "Ekspanzione posude grejačke - ZAVARIVAČ"
					},
					{
						"id": 190,
						"name": "Ekspanzione posude grejačke - PNEUMATEX"
					},
					{
						"id": 191,
						"name": "Ekspanzione posude grejačke - TERMORAD"
					},
					{
						"id": 192,
						"name": "Ekspanzione posude grejačke - DUKLA"
					},
					{
						"id": 193,
						"name": "Ekspanzione posude grejačke - RAZNO"
					},
					{
						"id": 194,
						"name": "Pribor za ekspanzione posude"
					}
				]
			},
			{
				"name": "Dimovodne cevi i sistemi",
				"groups": [
					{
						"id": 195,
						"name": "Dimovodne cevi - KEPO"
					},
					{
						"id": 196,
						"name": "Dimovodne cevi - ZAVARIVAČ"
					},
					{
						"id": 197,
						"name": "Dimovodne cevi - STANDARD"
					},
					{
						"id": 198,
						"name": "Štucne, vrata dimnjaka, ventilacione resetke - ANTIĆ KOSTA"
					},
					{
						"id": 199,
						"name": "Dimovodne cevi - BRAVAR"
					},
					{
						"id": 200,
						"name": "Dimovodne cevi - ALFA"
					},
					{
						"id": 201,
						"name": "Dimovodne cevi za peći na pelet - RAZNO"
					},
					{
						"id": 202,
						"name": "Dimovodni sistemi - SCHIEDEL"
					},
					{
						"id": 203,
						"name": "Dimovodni sistemi - KEPO"
					},
					{
						"id": 204,
						"name": "Dimovodne cevi i sistemi - RAZNO"
					},
					{
						"id": 205,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Merno regulaciona oprema",
				"groups": [
					{
						"id": 206,
						"name": "Merno regulaciona oprema - KRT"
					},
					{
						"id": 207,
						"name": "Merno regulaciona oprema - KINA"
					},
					{
						"id": 208,
						"name": "Merno regulaciona oprema - SITEM"
					},
					{
						"id": 209,
						"name": "Merno regulaciona oprema - SIEMENS"
					},
					{
						"id": 210,
						"name": "Merno regulaciona oprema - RAZNO"
					},
					{
						"id": 211,
						"name": "Sobni termostati - KINA"
					},
					{
						"id": 212,
						"name": "Sobni termostati - SALUS"
					},
					{
						"id": 213,
						"name": "Sobni termostati - RAZNO"
					},
					{
						"id": 214,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Solarni sistemi",
				"groups": [
					{
						"id": 215,
						"name": "Solarni kolektori - VIESSMANN"
					},
					{
						"id": 216,
						"name": "Solarni kolektori - CAMEL SOLAR"
					},
					{
						"id": 217,
						"name": "Nosači za solarne kolektore"
					},
					{
						"id": 218,
						"name": "Diferencijalni termostati"
					},
					{
						"id": 219,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Toplotne pumpe",
				"groups": [
					{
						"id": 220,
						"name": "Toplotne pumpe - MITSUBISHI"
					},
					{
						"id": 221,
						"name": "Toplotne pumpe - FERROLI"
					},
					{
						"id": 222,
						"name": "Toplotne pume - LG"
					},
					{
						"id": 223,
						"name": "Toplotne pumpe - VIESSMAN"
					}
				]
			},
			{
				"name": "Akumulacioni rezervoari, bojleri i oprema",
				"groups": [
					{
						"id": 224,
						"name": "Akumul. rezervoari, bojleri i oprema - RADIJATOR INŽENJERING"
					},
					{
						"id": 225,
						"name": "Akumul. rezervoari, bojleri i oprema - CENTROMETAL"
					},
					{
						"id": 226,
						"name": "Akumul. rezervoari, bojleri i oprema - BURNIT"
					},
					{
						"id": 227,
						"name": "Akumul. rezervoari, bojleri i oprema - BOSCH"
					},
					{
						"id": 228,
						"name": "Akumul. rezervoari, bojleri i oprema - RAZNO"
					}
				]
			},
			{
				"name": "Materijal za ogrev",
				"groups": [
					{
						"id": 229,
						"name": "Pelet"
					}
				]
			}
		]
	},
	{
		"text": "Vodovod i kanalizacija",
		"icon": "shower",
		"families": [
			{
				"name": "Vodovodne cevi i fiting",
				"groups": [
					{
						"id": 230,
						"name": "PE-X Cevi i fiting - INTERPLAST"
					},
					{
						"id": 231,
						"name": "PPR cevi i fiting- VALDOM"
					},
					{
						"id": 232,
						"name": "PPR cevi i fiting - PILSA"
					},
					{
						"id": 233,
						"name": "PPR cevi i fiting - PEŠTAN"
					},
					{
						"id": 234,
						"name": "PPR cevi i fiting - METAPLAST"
					},
					{
						"id": 235,
						"name": "PPR fiting - PILSA"
					},
					{
						"id": 236,
						"name": "Pocinkovani fiting (KRT) - KINA"
					},
					{
						"id": 237,
						"name": "Pocinkovani fiting - TITAN"
					},
					{
						"id": 238,
						"name": "Pocinkovani fiting - IBP"
					},
					{
						"id": 239,
						"name": "Hromirani fiting"
					},
					{
						"id": 240,
						"name": "Hromirani fiting - MINOTTI"
					},
					{
						"id": 241,
						"name": "Hromirani fiting"
					},
					{
						"id": 242,
						"name": "Gumice i dihtunzi"
					},
					{
						"id": 243,
						"name": "Mesingani fiting - DOMAĆI"
					},
					{
						"id": 244,
						"name": "Mesingani fiting - KOFORM"
					},
					{
						"id": 245,
						"name": "Mesingani fiting - SLOVARM"
					},
					{
						"id": 246,
						"name": "Mesingani fiting - RAZNO"
					},
					{
						"id": 247,
						"name": "PE-X Cevi i fiting"
					},
					{
						"id": 248,
						"name": "Čelične cevi pocinkovane"
					}
				]
			},
			{
				"name": "Kanalizacione cevi i fiting",
				"groups": [
					{
						"id": 249,
						"name": "Kanalizacione cevi i fiting - VALDOM"
					},
					{
						"id": 250,
						"name": "Kanalizacione cevi i fiting - PEŠTAN"
					},
					{
						"id": 251,
						"name": "Kanalizacione cevi i fitin - RAZNO"
					},
					{
						"id": 252,
						"name": "Drenažna creva"
					}
				]
			},
			{
				"name": "Okiten creva i armatura",
				"groups": [
					{
						"id": 253,
						"name": "Okiten creva - VALDOM"
					},
					{
						"id": 254,
						"name": "Okiten creva - RAZNO"
					},
					{
						"id": 255,
						"name": "Fiting za okiten - VALDOM"
					},
					{
						"id": 256,
						"name": "MS fiting za okiten"
					},
					{
						"id": 257,
						"name": "Fiting za okiten - UNIDELTA"
					},
					{
						"id": 258,
						"name": "Fiting za okiten - FERMESKOP"
					}
				]
			},
			{
				"name": "Sanitarije",
				"groups": [
					{
						"id": 259,
						"name": "Vodokotlići i pribor"
					},
					{
						"id": 260,
						"name": "WC šolje, pisoari i pribor"
					},
					{
						"id": 261,
						"name": "SLIVNICI I REŠETKE"
					},
					{
						"id": 262,
						"name": "Priključna creva armirana"
					},
					{
						"id": 263,
						"name": "Priključna creva - RAZNO"
					},
					{
						"id": 264,
						"name": "Sifoni i pribor"
					}
				]
			},
			{
				"name": "Pumpe za transport hladne i sanitarne vode",
				"groups": [
					{
						"id": 265,
						"name": "Pumpe za transport hladne i sanitarne vode - WILO"
					},
					{
						"id": 266,
						"name": "Pumpe za transport hladne i sanitarne vode - PEDROLLO"
					},
					{
						"id": 267,
						"name": "Pumpe za transport hladne i sanitarne vode - NOVOTERM PRO"
					},
					{
						"id": 268,
						"name": "Pumpe za transport hladne i sanitarne vode - RAZNO"
					},
					{
						"id": 269,
						"name": "Hidrofori i pribor"
					},
					{
						"id": 270,
						"name": "Pribor za pumpe za transport hladne vode"
					}
				]
			},
			{
				"name": "Hidroforske i sanitarne ekspanzione posude",
				"groups": [
					{
						"id": 271,
						"name": "Eskpanzione posude sanitarne - AQUASYSTEM"
					},
					{
						"id": 272,
						"name": "Ekspanzione posude sanitarne - VAREM"
					},
					{
						"id": 273,
						"name": "Ekspanzione posude sanitarne - ELBI"
					},
					{
						"id": 274,
						"name": "Ekspanzione posude sanitarne - RAZNO"
					},
					{
						"id": 275,
						"name": "Hidroforske posude -TERMORAD"
					},
					{
						"id": 276,
						"name": "Hidroforske posude -AQUASYSTEM"
					}
				]
			},
			{
				"name": "Vodovodna armatura",
				"groups": [
					{
						"id": 277,
						"name": "Vodovodna armatura - STRATO PLUS"
					},
					{
						"id": 278,
						"name": "Vodovodni ventili - SLOVARM"
					},
					{
						"id": 279,
						"name": "Vodovodna armatura - FERMES"
					},
					{
						"id": 280,
						"name": "Vodovodna armatura - VALVEX"
					},
					{
						"id": 281,
						"name": "Vodovodna armatura - ITAP"
					},
					{
						"id": 282,
						"name": "VODOVODNA ARMATURA - RAZNO"
					},
					{
						"id": 283,
						"name": "Virble"
					},
					{
						"id": 284,
						"name": "Spojnice reparacione"
					}
				]
			},
			{
				"name": "Baštenska creva i armatura",
				"groups": [
					{
						"id": 285,
						"name": "Baštenska creva"
					},
					{
						"id": 286,
						"name": "Baštenske slavine - IBP"
					},
					{
						"id": 287,
						"name": "Baštenske slavine - RAZNO"
					},
					{
						"id": 288,
						"name": "Spojnice"
					},
					{
						"id": 289,
						"name": "Prskalice i mlaznice"
					}
				]
			},
			{
				"name": "Bojleri i izmenjivači toplote",
				"groups": [
					{
						"id": 290,
						"name": "Bojleri - TERMORAD"
					},
					{
						"id": 291,
						"name": "Bojleri - ELIT INOX"
					},
					{
						"id": 292,
						"name": "Bojleri - TERMIL"
					},
					{
						"id": 293,
						"name": "Bojleri - GORENJE"
					},
					{
						"id": 294,
						"name": "Bojleri - ELMAC"
					},
					{
						"id": 295,
						"name": "Bojleri - TESY"
					},
					{
						"id": 296,
						"name": "Bojleri - METALAC"
					},
					{
						"id": 297,
						"name": "Bojleri - INOX-PRERADA"
					},
					{
						"id": 298,
						"name": "BOJLERI - EGV"
					},
					{
						"id": 299,
						"name": "Bojleri - DRAŽICE"
					},
					{
						"id": 300,
						"name": "Bojleri - RAZNO"
					},
					{
						"id": 301,
						"name": "Rezervni delovi za bojlere - TERMORAD"
					},
					{
						"id": 302,
						"name": "Oprema za bojlere"
					},
					{
						"id": 303,
						"name": "Grejači za bojlere - IEG"
					},
					{
						"id": 304,
						"name": "Grejači - RAZNO"
					},
					{
						"id": 305,
						"name": "Izmenjivači toplote"
					}
				]
			},
			{
				"name": "Baterije tuševi i pribor",
				"groups": [
					{
						"id": 306,
						"name": "Baterije, tuševi i pribor - CLEVER"
					},
					{
						"id": 307,
						"name": "Baterije, tuševi i pribor - MINOTTI"
					},
					{
						"id": 308,
						"name": "Baterije, tuševi i pribor - FIORE"
					},
					{
						"id": 309,
						"name": "Baterije, tuševi i pribor - SLOVARM"
					},
					{
						"id": 310,
						"name": "Baterije, tuševi i pribor - KOMAK"
					},
					{
						"id": 311,
						"name": "Baterije, tuševi i pribor - RAZNO"
					},
					{
						"id": 312,
						"name": "Delovi za tuševe i baterije"
					},
					{
						"id": 313,
						"name": "Pribor za tuševe i baterije"
					}
				]
			},
			{
				"name": "Oprema za kupatila",
				"groups": [
					{
						"id": 314,
						"name": "Kade i lavaboi"
					},
					{
						"id": 315,
						"name": "Pribor i delovi za kade i lavaboe"
					},
					{
						"id": 316,
						"name": "Kupatilski nameštaj"
					},
					{
						"id": 317,
						"name": "Tuš kabine"
					},
					{
						"id": 318,
						"name": "Ventilatori - Antić Kosta"
					},
					{
						"id": 319,
						"name": "Ventilatori - TEXO ELITE"
					},
					{
						"id": 320,
						"name": "Galanterija i oprema za kupatilo"
					},
					{
						"id": 321,
						"name": "Neaktivne pozicije"
					}
				]
			},
			{
				"name": "Vodomeri, hidranti i pribor",
				"groups": [
					{
						"id": 322,
						"name": "Vodomeri i pribor"
					},
					{
						"id": 323,
						"name": "Hidranti i pribor"
					}
				]
			},
			{
				"name": "Prečišćavanje i omekšavanje vode",
				"groups": [
					{
						"id": 324,
						"name": "Filteri i pribor - KOMEO"
					},
					{
						"id": 325,
						"name": "Filteri i pribor - RAZNO"
					}
				]
			}
		]
	},
	{
		"text": "Klimatizacija",
		"icon": "ac_unit",
		"families": [
			{
				"name": "Bakarne cevi - EN12735",
				"groups": [
					{
						"id": 326,
						"name": "Bakarne cevi odmašćene - prave tvrde 5m"
					},
					{
						"id": 327,
						"name": "Bakarne cevi - u koturu"
					},
					{
						"id": 328,
						"name": "Bakarne cevi - izolovane"
					}
				]
			},
			{
				"name": "Klima uređaji",
				"groups": [
					{
						"id": 329,
						"name": "Klima uređaji - VIVAX"
					},
					{
						"id": 330,
						"name": "Klima uređaji - GREE"
					},
					{
						"id": 331,
						"name": "Klima uređaji - MIDEA"
					},
					{
						"id": 332,
						"name": "Klima uređaji - HISENSE"
					},
					{
						"id": 333,
						"name": "Klima uređaji - VEISSMAN"
					},
					{
						"id": 334,
						"name": "Klima uređaji - LG"
					},
					{
						"id": 335,
						"name": "Klima uređaji VOX"
					},
					{
						"id": 336,
						"name": "Oprema za klima uređaje"
					}
				]
			},
			{
				"name": "Fan coil uređaji",
				"groups": [
					{
						"id": 337,
						"name": "Fan coil uređaji - SABIANA"
					},
					{
						"id": 338,
						"name": "Fan coil uređaji - MIDEA"
					},
					{
						"id": 339,
						"name": "Fan coil uređaji - PROTEAN"
					},
					{
						"id": 340,
						"name": "Fan coil uređaji - OLIMPIA SPLENDID"
					},
					{
						"id": 341,
						"name": "Fan coil uređaji - GALLETTI ESTRO"
					},
					{
						"id": 342,
						"name": "Oprema za fan coil uređaje"
					}
				]
			},
			{
				"name": "Ventilacija",
				"groups": [
					{
						"id": 343,
						"name": "Grejalice na struju"
					},
					{
						"id": 344,
						"name": "Kaloriferi i pribor"
					},
					{
						"id": 345,
						"name": "Ventilatori"
					},
					{
						"id": 346,
						"name": "Radijatori"
					}
				]
			}
		]
	},
	{
		"text": "Alati i pribor",
		"icon": "construction",
		"families": [
			{
				"name": "Alati i pribori",
				"groups": [
					{
						"id": 347,
						"name": "Alati - ROTHENBERGER"
					},
					{
						"id": 348,
						"name": "Alati - REMS"
					},
					{
						"id": 349,
						"name": "Alat za Al-Pex"
					},
					{
						"id": 350,
						"name": "Alat za domaćinstvo - RAZNO"
					},
					{
						"id": 351,
						"name": "Zaštitna oprema"
					}
				]
			},
			{
				"name": "Pribor za fiksiranje i kačenje",
				"groups": [
					{
						"id": 352,
						"name": "Pribor za fiksiranje i kačenje - FISCHER"
					},
					{
						"id": 353,
						"name": "Pribor za fiksiranje i kačenje - OSTALO"
					},
					{
						"id": 354,
						"name": "Metalne šelne"
					},
					{
						"id": 355,
						"name": "Šelne za creva"
					},
					{
						"id": 356,
						"name": "PVC šelne i rozetne - POLY"
					}
				]
			},
			{
				"name": "Pribor za lemljenje, zavarivanje i zaptivanje",
				"groups": [
					{
						"id": 357,
						"name": "Pribor za lemljenje, zavarivanje i zaptivanje - FELDER"
					},
					{
						"id": 358,
						"name": "Pribor za lemljenje, zavarivanje i zaptivanje - HENKEL"
					},
					{
						"id": 359,
						"name": "Pribor za lemljenje, zavarivanje i zaptivanje - RAZNO"
					},
					{
						"id": 360,
						"name": "Gas MAPP"
					},
					{
						"id": 361,
						"name": "Teflon"
					},
					{
						"id": 362,
						"name": "Silikon"
					},
					{
						"id": 363,
						"name": "Pur pena"
					}
				]
			}
		]
	}
]