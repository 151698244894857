/** @format */

import styled from "styled-components";
import { useLocation } from "react-router-dom";

const StyledProductsPage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	color: black;
`;

const ProductsPage = () => {
	const location = useLocation();

	return (
		<StyledProductsPage>
			<h2>Grupe proizvoda</h2>
			<h3>{location.state}</h3>
		</StyledProductsPage>
	);
};

export default ProductsPage;
