// import rabatPrice from '../utils/rabatPrice';

export const rows = [
	{
		id: 1, // this is the id of the order (broj porudžbine)
		dateTimeStamp: '2022-09-13T10:01:08+0200',
		buyer: 'Central-H',
		address: '11 bb Majdanpek',
		streetName: '11',
		streetNumber: 'bb',
		city: 'Majdanpek',
		zipCode: '11234',
		price: 100, // should be total amount of the order, or not, since FE calculates from the order.body array (not needed at the moment)
		status: 'U pripremi',
		color: 'primary',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_lvYz9aN54RZR16TqlnC-uJsMuI6_rc8VWYK6MkpXwA&s"
		// },
		// order: 'Bakarni fiting',
		// url: 'https://panov.rs',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[1, 509015, "Dvodelno koleno 91 fi1", "kom", "846,00", "22,00", "25,00%", "20,00%", "16,50", "16.750,80"],
				[2, 509016, "Dvodelno koleno 99 fi2", "kom", "1.593,00", "94,00", "25,00%", "20,00%", "70,50", "134.767,80"],
				[3, 509017, "Dvodelno koleno 99 fi3", "kom", "887,00", "14,00", "25,00%", "20,00%", "10,50", "11.176,20"],
			],
			footer: '',
		},
	},
	{
		id: 2,
		dateTimeStamp: '2022-09-12T10:01:08+0200',
		buyer: 'Etaž',
		address: '12 bb Beograd',
		streetName: '12',
		streetNumber: 'bb',
		city: 'Beograd',
		zipCode: '',
		price: 88000,
		status: 'Prihvaćena',
		color: 'success',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJx9YImo8Z72E45Q6-Qgyd-Frsp8ofQOo9stRLBDINDQ&s"
		// },
		// order: 'Loctite 55'
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[4, 509018, "Dvodelno koleno 98 fi1", "kom", "1.406,00", "51,00", "25,00%", "20,00%", "38,25", "64.535,40"],
				[5, 509019, "Dvodelno koleno 94 fi2", "kom", "53,00", "28,00", "25,00%", "20,00%", "21,00", "1.335,60"],
				[6, 509020, "Dvodelno koleno 92 fi3", "kom", "206,00", "20,00", "25,00%", "20,00%", "15,00", "3.708,00"],
				[7, 509021, "Dvodelno koleno 95 fi4", "kom", "1.131,00", "80,00", "25,00%", "20,00%", "60,00", "81.432,00"],
				[8, 509022, "Dvodelno koleno 94 fi5", "kom", "598,00", "89,00", "25,00%", "20,00%", "66,75", "47.899,80"],
				[9, 509023, "Dvodelno koleno 100 fi6", "kom", "908,00", "78,00", "25,00%", "20,00%", "58,50", "63.741,60"],
				[10, 509024, "Dvodelno koleno 96 fi7", "kom", "1.966,00", "28,00", "25,00%", "20,00%", "21,00", "49.543,20"],
			],
			footer: '',
		},
	},
	{
		id: 3,
		dateTimeStamp: '2021-09-14T10:01:08+0200',
		buyer: 'Forum',
		address: '13 bb Novi Sad',
		streetName: '13',
		streetNumber: 'bb',
		city: 'Novi Sad',
		zipCode: '',
		price: 163444,
		status: 'Odbijena',
		color: 'error',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnKkBXw1adOEHEJn2hA3c_I9HrUIlHrOw-hxm30Q-U8w&s"
		// },
		// order: 'Plamaflex',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[11, 509025, "Dvodelno koleno 98 fi1", "kom", "1.545,00", "3,00", "25,00%", "20,00%", "2,25", "4.171,50"],
				[12, 509026, "Dvodelno koleno 99 fi2", "kom", "904,00", "96,00", "25,00%", "20,00%", "72,00", "78.105,60"],
				[13, 509026, "Dvodelno koleno 99 fi3", "kom", "137,00", "78,00", "25,00%", "20,00%", "58,50", "9.617,40"],
				[14, 509027, "Dvodelno koleno 98 fi4", "kom", "1.077,00", "5,00", "25,00%", "20,00%", "3,75", "4.846,50"],
				[15, 509028, "Dvodelno koleno 99 fi5", "kom", "142,00", "3,00", "25,00%", "20,00%", "2,25", "383,40"],
			],
			footer: '',
		},
	},
	{
		id: 4,
		dateTimeStamp: '2022-09-15T10:01:08+0200',
		buyer: 'Termaš MKS',
		address: '14 bb Kladovo',
		streetName: '14',
		streetNumber: 'bb',
		city: 'Kladovo',
		zipCode: '',
		price: 82700,
		status: 'Izmenjena',
		color: 'warning',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSJbyOI9BrGYSYdpMkMmYK2nJrAkEn5Q2yoYEjKXcZpHw&s"
		// },
		// order: 'Kuglasti ventil',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[16, 509029, "Dvodelno koleno 99 fi1", "kom", "1.292,00", "76,00", "25,00%", "20,00%", "57,00", "88.372,80"],
				[17, 509030, "Dvodelno koleno 94 fi2", "kom", "1.323,00", "1,00", "25,00%", "20,00%", "0,75", "1.190,70"],
				[18, 509031, "Dvodelno koleno 92 fi3", "kom", "1.710,00", "45,00", "25,00%", "20,00%", "33,75", "69.255,00"],
				[19, 509032, "Dvodelno koleno 91 fi4", "kom", "82,00", "39,00", "25,00%", "20,00%", "29,25", "2.878,20"],
			],
			footer: '',
		},
	},
	{
		id: 5,
		dateTimeStamp: '2022-09-15T10:00:08+0200',
		buyer: 'Momix & Shomix',
		address: 'Braće Vićentijević 73 Beli Potok, Beograd',
		streetName: 'Braće Vićentijević',
		streetNumber: '73',
		city: 'Beli Potok, Beograd',
		zipCode: '',
		price: 22755,
		status: 'Prihvaćena',
		color: 'success',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS6Lq9B-EQGE4CnbUAxh1iEo4BaOSvdTkNjrQ&usqp=CAU"
		// },
		// order: 'Radijatorski ventil',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[20, 509033, "Dvodelno koleno 98 fi1", "kom", "308,00", "39,00", "25,00%", "20,00%", "29,25", "10.810,80"],
				[21, 509034, "Dvodelno koleno 97 fi2", "kom", "1.908,00", "59,00", "25,00%", "20,00%", "44,25", "101.314,80"],
				[22, 509035, "Dvodelno koleno 92 fi3", "kom", "116,00", "51,00", "25,00%", "20,00%", "38,25", "5.324,40"],
				[23, 509036, "Dvodelno koleno 96 fi4", "kom", "1.432,00", "65,00", "25,00%", "20,00%", "48,75", "83.772,00"],
				[24, 509037, "Dvodelno koleno 93 fi5", "kom", "192,00", "46,00", "25,00%", "20,00%", "34,50", "7.948,80"],
				[25, 509038, "Dvodelno koleno 92 fi6", "kom", "1.601,00", "47,00", "25,00%", "20,00%", "35,25", "67.722,30"],
				[26, 509039, "Dvodelno koleno 97 fi7", "kom", "893,00", "61,00", "25,00%", "20,00%", "45,75", "49.025,70"],
				[27, 509040, "Dvodelno koleno 93 fi8", "kom", "1.897,00", "95,00", "25,00%", "20,00%", "71,25", "162.193,50"],
				[28, 509041, "Dvodelno koleno 93 fi9", "kom", "1.824,00", "54,00", "25,00%", "20,00%", "40,50", "88.646,40"],
			],
			footer: '',
		},
	},
	{
		id: 6,
		dateTimeStamp: '2022-09-17T10:01:08+0200',
		buyer: 'Grejanje Inženjering',
		address: 'Mileta veselog 23, Vrbas',
		streetName: 'Mileta veselog',
		streetNumber: '23',
		city: 'Vrbas',
		zipCode: '',
		price: 122817,
		status: 'U obradi',
		color: 'info',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXJNfQKJeZrsV5Ewc4IhRVdgbLeydIBPu9ShfrAtq1HXOaR0jEK1caw0z1BIU9KPQVx8A&usqp=CAU"
		// },
		// order: 'Slavina',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[29, 509042, "Dvodelno koleno 93 fi1", "kom", "70,00", "44,00", "25,00%", "20,00%", "33,00", "2.772,00"],
				[30, 509043, "Dvodelno koleno 98 fi2", "kom", "1.866,00", "39,00", "25,00%", "20,00%", "29,25", "65.496,60"],
				[31, 509044, "Dvodelno koleno 93 fi3", "kom", "1.202,00", "49,00", "25,00%", "20,00%", "36,75", "53.008,20"],
				[32, 509045, "Dvodelno koleno 96 fi4", "kom", "893,00", "52,00", "25,00%", "20,00%", "39,00", "41.792,40"],
				[33, 509046, "Dvodelno koleno 93 fi5", "kom", "1.164,00", "17,00", "25,00%", "20,00%", "12,75", "17.809,20"],
				[34, 509047, "Dvodelno koleno 94 fi6", "kom", "678,00", "11,00", "25,00%", "20,00%", "8,25", "6.712,20"],
			],
			footer: '',
		},
	},
	{
		id: 7,
		dateTimeStamp: '2022-09-18T10:01:08+0200',
		buyer: 'Mašinac d.o.o.',
		address: 'Višnjički venac 111, Beograd',
		streetName: 'Višnjički venac',
		streetNumber: '111',
		city: 'Beograd',
		zipCode: '',
		price: 77777,
		status: 'U obradi',
		color: 'info',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS9dlVy3pjPRdv1eQ0OIdaSHSB4BGr971S_PzLINjqh0GddtaVdDgc8N1yptT4oMQ1Tmr8&usqp=CAU"
		// },
		// order: 'Uronska cev',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[35, 509048, "Dvodelno koleno 93 fi1", "kom", "273,00", "14,00", "25,00%", "20,00%", "10,50", "3.439,80"],
				[36, 509049, "Dvodelno koleno 96 fi2", "kom", "129,00", "99,00", "25,00%", "20,00%", "74,25", "11.493,90"],
			],
			footer: '',
		},
	},
	{
		id: 8,
		dateTimeStamp: '2022-09-19T10:01:08+0200',
		buyer: 'Gradska čistoća',
		address: 'Vladetina, Milanovac',
		streetName: 'Vladetina',
		streetNumber: null,
		city: 'Milanovac',
		zipCode: '',
		price: 27145.23,
		status: 'U obradi',
		color: 'info',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQkRVfmxoB3IaD-beFQeGPGH85odNUua3PK2EllKXefkn0kCaHPq4c6GAYLGy9a6WN5JEo&usqp=CAU"
		// },
		// order: 'Grejni kabl',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[37, 509050, "Dvodelno koleno 93 fi1", "kom", "1.542,00", "63,00", "25,00%", "20,00%", "47,25", "87.431,40"],
				[38, 509051, "Dvodelno koleno 99 fi2", "kom", "63,00", "54,00", "25,00%", "20,00%", "40,50", "3.061,80"],
				[39, 509052, "Dvodelno koleno 93 fi3", "kom", "807,00", "82,00", "25,00%", "20,00%", "61,50", "59.556,60"],
			],
			footer: '',
		},
	},
	{
		id: 9,
		dateTimeStamp: '2022-09-20T10:01:08+0200',
		buyer: 'Thermal cloud',
		address: 'Turgutreis, Marmaris, Muğla, Turska',
		streetName: 'Turgutreis',
		streetNumber: null,
		city: 'Marmaris, Muğla, Turska',
		zipCode: '',
		price: 866755,
		status: 'Odbijena',
		color: 'error',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIVNQqZmaak1KBTAwDsk8DOq6Pg4YITDjcai7LLa3VncNf5tcNj-MeJNV6KhOBQesr2A&usqp=CAU"
		// },
		// order: 'neki naziv robe',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[40, 509053, "Dvodelno koleno 96 fi1", "kom", "1.709,00", "4,00", "25,00%", "20,00%", "3,00", "6.152,40"],
				[41, 509054, "Dvodelno koleno 96 fi2", "kom", "1.507,00", "54,00", "25,00%", "20,00%", "40,50", "73.240,20"],
			],
			footer: '',
		},
	},
	{
		id: 10,
		dateTimeStamp: '2022-09-21T10:01:08+0200',
		buyer: 'Thermo park',
		address: 'Matije Gupca 1, Beograd',
		streetName: 'Matije Gupca',
		streetNumber: '1',
		city: 'Beograd',
		zipCode: '',
		price: 86761,
		status: 'Odbijena',
		color: 'error',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIVNQqZmaak1KBTAwDsk8DOq6Pg4YITDjcai7LLa3VncNf5tcNj-MeJNV6KhOBQesr2A&usqp=CAU"
		// },
		// order: 'neki naziv robe',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[42, 509055, "Dvodelno koleno 92 fi1", "kom", "1.912,00", "42,00", "25,00%", "20,00%", "31,50", "72.273,60"],
				[43, 509056, "Dvodelno koleno 92 fi2", "kom", "1.887,00", "62,00", "25,00%", "20,00%", "46,50", "105.294,60"],
				[44, 509057, "Dvodelno koleno 91 fi3", "kom", "1.707,00", "32,00", "25,00%", "20,00%", "24,00", "49.161,60"],
				[45, 509058, "Dvodelno koleno 99 fi4", "kom", "851,00", "65,00", "25,00%", "20,00%", "48,75", "49.783,50"],
				[46, 509059, "Dvodelno koleno 100 fi5", "kom", "1.708,00", "0,00", "25,00%", "20,00%", "0,00", "0,00"],
				[47, 509060, "Dvodelno koleno 96 fi6", "kom", "1.059,00", "22,00", "25,00%", "20,00%", "16,50", "20.968,20"],
				[48, 509061, "Dvodelno koleno 91 fi7", "kom", "1.560,00", "56,00", "25,00%", "20,00%", "42,00", "78.624,00"],
				[49, 509062, "Dvodelno koleno 92 fi8", "kom", "1.376,00", "51,00", "25,00%", "20,00%", "38,25", "63.158,40"],
				[50, 509063, "Dvodelno koleno 92 fi9", "kom", "604,00", "80,00", "25,00%", "20,00%", "60,00", "43.488,00"],
				[51, 509064, "Dvodelno koleno 97 fi10", "kom", "1.883,00", "83,00", "25,00%", "20,00%", "62,25", "140.660,10"],
				[52, 509065, "Dvodelno koleno 99 fi11", "kom", "1.687,00", "86,00", "25,00%", "20,00%", "64,50", "130.573,80"],
				[53, 509066, "Dvodelno koleno 94 fi12", "kom", "1.010,00", "32,00", "25,00%", "20,00%", "24,00", "29.088,00"],
			],
			footer: '',
		},
	},
	{
		id: 11,
		dateTimeStamp: '2022-09-22T10:01:08+0200',
		buyer: 'Thermico',
		address: 'Vojvode Stepe 123, Beograd',
		streetName: 'Vojvode Stepe',
		streetNumber: '123',
		city: 'Beograd',
		zipCode: '',
		price: 66155,
		status: 'Odbijena',
		color: 'error',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIVNQqZmaak1KBTAwDsk8DOq6Pg4YITDjcai7LLa3VncNf5tcNj-MeJNV6KhOBQesr2A&usqp=CAU"
		// },
		// order: 'neki naziv robe',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[54, 509067, "Dvodelno koleno 96 fi1", "kom", "909,00", "14,00", "25,00%", "20,00%", "10,50", "11.453,40"],
				[55, 509068, "Dvodelno koleno 98 fi2", "kom", "1.580,00", "71,00", "25,00%", "20,00%", "53,25", "100.962,00"],
				[56, 509069, "Dvodelno koleno 93 fi3", "kom", "1.923,00", "8,00", "25,00%", "20,00%", "6,00", "13.845,60"],
				[57, 509070, "Dvodelno koleno 92 fi4", "kom", "1.820,00", "77,00", "25,00%", "20,00%", "57,75", "126.126,00"],
				[58, 509071, "Dvodelno koleno 93 fi5", "kom", "112,00", "7,00", "25,00%", "20,00%", "5,25", "705,60"],
				[59, 509072, "Dvodelno koleno 91 fi6", "kom", "1.039,00", "54,00", "25,00%", "20,00%", "40,50", "50.495,40"],
				[60, 509073, "Dvodelno koleno 99 fi7", "kom", "1.538,00", "34,00", "25,00%", "20,00%", "25,50", "47.062,80"],
				[61, 509074, "Dvodelno koleno 100 fi8", "kom", "761,00", "20,00", "25,00%", "20,00%", "15,00", "13.698,00"],
				[62, 509075, "Dvodelno koleno 99 fi9", "kom", "19,00", "63,00", "25,00%", "20,00%", "47,25", "1.077,30"],
				[63, 509076, "Dvodelno koleno 95 fi10", "kom", "1.114,00", "77,00", "25,00%", "20,00%", "57,75", "77.200,20"],
				[64, 509077, "Dvodelno koleno 95 fi11", "kom", "1.605,00", "42,00", "25,00%", "20,00%", "31,50", "60.669,00"],
				[65, 509078, "Dvodelno koleno 93 fi12", "kom", "612,00", "9,00", "25,00%", "20,00%", "6,75", "4.957,20"],
				[66, 509079, "Dvodelno koleno 94 fi13", "kom", "681,00", "56,00", "25,00%", "20,00%", "42,00", "34.322,40"],
				[67, 509080, "Dvodelno koleno 100 fi14", "kom", "1.383,00", "15,00", "25,00%", "20,00%", "11,25", "18.670,50"],
				[68, 509081, "Dvodelno koleno 98 fi15", "kom", "33,00", "97,00", "25,00%", "20,00%", "72,75", "2.880,90"],
				[69, 509082, "Dvodelno koleno 94 fi16", "kom", "1.866,00", "17,00", "25,00%", "20,00%", "12,75", "28.549,80"],
				[70, 509083, "Dvodelno koleno 91 fi17", "kom", "1.542,00", "68,00", "25,00%", "20,00%", "51,00", "94.370,40"],
			],
			footer: '',
		},
	},
	{
		id: 12,
		dateTimeStamp: '2022-09-23T10:01:08+0200',
		buyer: 'Petrovac',
		address: 'Temerinska 1, Petrovac',
		streetName: 'Temerinska',
		streetNumber: '1',
		city: 'Petrovac',
		zipCode: '',
		price: 123123,
		status: 'Odbijena',
		color: 'error',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIVNQqZmaak1KBTAwDsk8DOq6Pg4YITDjcai7LLa3VncNf5tcNj-MeJNV6KhOBQesr2A&usqp=CAU"
		// },
		// order: 'neki naziv robe',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[71, 509084, "Dvodelno koleno 100 fi1", "kom", "1.366,00", "92,00", "25,00%", "20,00%", "69,00", "113.104,80"],
				[72, 509085, "Dvodelno koleno 97 fi2", "kom", "296,00", "62,00", "25,00%", "20,00%", "46,50", "16.516,80"],
				[73, 509086, "Dvodelno koleno 91 fi3", "kom", "1.422,00", "18,00", "25,00%", "20,00%", "13,50", "23.036,40"],
			],
			footer: '',
		},
	},
	{
		id: 13,
		dateTimeStamp: '2022-09-24T10:01:08+0200',
		buyer: 'Banatski Dvor',
		address: 'Omladinskih brigada 1, Novi Sad',
		streetName: 'Omladinskih brigada',
		streetNumber: '1',
		city: 'Novi Sad',
		zipCode: '',
		price: 34552,
		status: 'Prihvaćena',
		color: 'success',
		// image: {
		//     avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREIVNQqZmaak1KBTAwDsk8DOq6Pg4YITDjcai7LLa3VncNf5tcNj-MeJNV6KhOBQesr2A&usqp=CAU"
		// },
		// order: 'neki naziv robe',
		order: {
			header: ['R. Br.', 'ID', 'Naziv', 'J. M.', 'Količina', 'VP Cena', 'Rabat (%)', 'PDV (%)', 'Cena', 'Vrednost'],
			body: [
				[74, 509087, "Dvodelno koleno 100 fi1", "kom", "759,00", "46,00", "25,00%", "20,00%", "34,50", "31.422,60"],
				[75, 509088, "Dvodelno koleno 97 fi2", "kom", "139,00", "39,00", "25,00%", "20,00%", "29,25", "4.878,90"],
				[76, 509089, "Dvodelno koleno 97 fi3", "kom", "672,00", "37,00", "25,00%", "20,00%", "27,75", "22.377,60"],
				[77, 509090, "Dvodelno koleno 91 fi4", "kom", "1.340,00", "59,00", "25,00%", "20,00%", "44,25", "71.154,00"],
				[78, 509091, "Dvodelno koleno 100 fi5", "kom", "1.861,00", "68,00", "25,00%", "20,00%", "51,00", "113.893,20"],
				[79, 509092, "Dvodelno koleno 99 fi6", "kom", "7,00", "5,00", "25,00%", "20,00%", "3,75", "31,50"],
				[80, 509093, "Dvodelno koleno 94 fi7", "kom", "844,00", "10,00", "25,00%", "20,00%", "7,50", "7.596,00"],
			],
			footer: '',
		},
	},
];