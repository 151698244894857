/** @format */

import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import InfoCards from "./InfoCards";

//Import Material React Table Translations
import { MRT_Localization_SR_LATN_RS } from "../utils/MRT_Localization_SR_LATN_RS.js";

// import Chip from "@mui/material/Chip";

import { rows } from "../temp_data/financial_state_data";

// import { SideDetailsContext } from "../contexts/SideDetailsContext";

const formatDate = (date) => {
	const parsedDate = new Date(date);
	return parsedDate.toLocaleString("sr", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});
};

const formatCurrency = (value) => {
	return value.toLocaleString("sr", {
		style: "currency",
		currency: "RSD",
		minimumFractionDigits: 2,
	});
};

// simple data example - Check out https://www.material-react-table.com/docs/examples/remote for a more complex example

const OrderTable = () => {
	// const { setContextValue } = React.useContext(SideDetailsContext);
	// const [clickedRowId, setClickedRowId] = React.useState(null);

	React.useEffect(() => {
		window.onload = () => {
			const horizontal_scroll = document.querySelector(".ch-table-container");
			horizontal_scroll.children[1].classList.add("scroll-handler");
		};
	}, []);

	const columns = useMemo(
		() => [
			{
				accessorKey: "id",
				header: "ID",
				size: 50,
			},
			{
				accessorKey: "document_number",
				header: "Broj dokumenta",
			},
			{
				accessorFn: (row) => formatDate(row.date),
				id: "date",
				header: "Datum",
			},
			{
				accessorFn: (row) => formatDate(row.payment_due_date),
				id: "payment_due_date",
				header: "Valuta",
			},
			{
				accessorFn: (row) => formatCurrency(row.owes_amount),
				id: "owes_amount",
				header: "Duguje",
				size: 90,
			},
			{
				accessorFn: (row) => formatCurrency(row.demands_amount),
				id: "demands_amount",
				header: "Potražuje",
				size: 90,
			},
		],
		[],
	);

	const cardsData = [
		{
			title: "Ukupno zaduženje",
			number: 123,
		},
		{
			title: "Dospele obaveze",
			number: 456,
		},
		{
			title: "Nedospele obaveze",
			number: 789,
		},
	];

	return (
		<>
			<InfoCards cardsData={cardsData} />
			<MaterialReactTable
				localization={MRT_Localization_SR_LATN_RS}
				columns={columns}
				data={rows}
				muiTablePaperProps={{
					//change the mui box shadow
					elevation: 0,
					//customize paper styles
					sx: {
						borderRadius: "0",
						borderBottom: "1px solid #e0e0e0",
						cursor: "pointer",
					},
					className: "ch-table-container",
				}}
				enableColumnResizing
				columnResizeMode="onChange"
				// muiTableBodyRowProps={({ row }) => ({
				// 	onClick: () => handleTableRowClick(row), //consider implementing class animate-sideDetails here, instead in App.js
				// 	className: rowClassName(row.id),
				// })}
			/>
		</>
	);
};

export default OrderTable;
